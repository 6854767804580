import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { AlertService } from "@full-fledged/alerts";
import { link } from "fs";
import { TPWInterface } from "ngx-typewriter";
import { ApiService } from "src/app/Service/api.service";

@Component({
    selector: "app-home-four",
    templateUrl: "./home-four.component.html",
    styleUrls: ["./home-four.component.scss"],
})
export class HomeFourComponent implements OnInit {
    public options: TPWInterface = {
        textList: ["Connect", "Automate", "Visualize", "Explore", "Act on"],
        speed: 300,
        loop: true,
        delay: 500,
    };
    subscriptionForm: FormGroup;
    message = "";
    email: FormControl = new FormControl("", [
        Validators.required,
        Validators.email,
    ]);
    host = 'https://singleinsight-open.vercel.app';
    content = [
        {
          title: 'Connectivity',
          description: 'Easily connect with and integrate data from enterprise systems, IoT, and API sources.',
          link: `${this.host}/main/connect/new-connection`,
          linkText: 'Click here to see a sample of what you can connect to.'
        },
        {
          title: 'Analytics and Modeling',
          description: 'Automated and in real-time; no more wasted time and labor collecting and processing data.',
          link:`${this.host}/main/models/new-model`,
          linkText:'Click here to see models'
        },
        {
          title: 'Visualization',
          description: 'Dashboards, reports, and digital twins in user-friendly formats.',
          link: `${this.host}/main/dashboard/templates`,
          linkText: 'Show examples!'
        },
        {
          title: 'Pre-Built Job-Function Modules',
          description: 'Pre-built use-cases address the needs of specific job functions with ready-to-use analytics, dashboards, and reports. Gets your team up and running fast.',
          link: `${this.host}/main/ai-applications`,
          linkText: 'Click here to explore use cases.'
        },
        {
          title: 'Single Platform',
          description: 'Merge data from IoT and OT systems to give each person on your team one place to access all the information, analysis, and insights they need to do their job.'
        },
        {
          title: 'AI',
          description: 'Natural-language requests and a recommendation engine (trained on your company’s data) provide insights and efficiencies beyond what analytics alone can provide. Let AI do the hard stuff and discover opportunities that are hiding in your data.'
        },
        {
          title: 'World-Class Security',
          description: 'SOC 2 Type II Compliant; We take your cybersecurity seriously and fully comply to rigorous industry standards. We also give you the option of hosting your data yourself, with Microsoft, or with AWS; whatever you choose, only you have access to your data.'
        },
        {
          title: 'Customization / Self-Serve',
          description: 'Our no-code and low-code software tools enable you and your team to make the customizations you want (or create new use-cases from scratch), without the need for IT. Or, we can customize or create new use-cases for you.'
        },
        {
          title: 'Digital Transformation Maturity',
          description: 'Whether you\'re still using spreadsheets and paper or have some amount of automation, we can get you to the next level.',
          // link: '/digital-maturity-survey',
          // linkText: 'Take our digital maturity survey to see where you stand.'
        },
        {
          title: 'Enterprise-wide / Scalability',
          description: 'With the need for data in almost every corner of your enterprise, we can provide solutions for any department or function, an entire facility, or across your global operations. We grow with your business.'
        }
      ];
    constructor(
        private api: ApiService,
        private fb: FormBuilder,
        private toast: AlertService
    ) {}
    ngOnInit() {
        this.subscriptionForm = this.fb.group({
            email: this.email,
        });
    }

    submit() {
        const payload = {
            email: this.subscriptionForm.value.email,
        };

        this.api.mailchimpSeeDemoAudience(payload).subscribe((res) => {
            console.log(res);

            this.message = "Email received.";
        });
    }
}
