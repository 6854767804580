<!-- Start About Area -->
<section class="about-area ptb-110">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <!-- <img src="assets/img/about/about1.jpg" alt="image">
                    <img src="assets/img/about/about2.jpg" alt="image"> -->
                    <img
                        src="../../../assets/img/About_us_pic.jpg"
                        alt="image"
                    />
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h3>Welcome to the future of manufacturing intelligence</h3>
                    <br />
                    <br />
                    <p>
                        We are at the forefront of the Manufacturing Data
                        Analytics industry. Transforming data into meaningful
                        insights and driving success for manufacturing
                        businesses worldwide.
                    </p>
                    <p>
                        We're pioneers in leveraging data to optimize
                        manufacturing processes, pushing the boundaries of
                        what's possible in the realm of efficiency and
                        productivity.
                    </p>
                    <p>
                        Our tailored analytics solutions blend cutting-edge
                        technology with deep industry know-how to transform raw
                        data into actionable insights that drive operational
                        excellence.
                    </p>
                    <p>
                        At Single Insight, we don't just analyze data-we give it
                        purpose. Discover how our innovative solutions can shape
                        your manufacturing future. Explore our services, connect
                        with our team, and start your journey towards a smarter,
                        more productive tomorrow with Single Insight. Welcome to
                        the future of manufacturing intelligence.
                    </p>
                    <a routerLink="/about" class="btn btn-primary"
                        >Discover More</a
                    >
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img1">
        <img src="assets/img/shape/shape1.png" alt="image" />
    </div>
    <div class="shape-img2">
        <img src="assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="assets/img/shape/shape3.png" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="assets/img/shape/shape4.svg" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="assets/img/shape/shape5.svg" alt="image" />
    </div>
    <div class="shape-img6">
        <img src="assets/img/shape/shape6.png" alt="image" />
    </div>
    <div class="dot-shape1">
        <img src="assets/img/shape/dot1.png" alt="image" />
    </div>
    <div class="dot-shape2">
        <img src="assets/img/shape/dot3.png" alt="image" />
    </div>
</section>
<!-- End About Area -->
