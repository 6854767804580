
  const logoData: any[] = [
    {
      id: 1,
      category: 'databases',
      categoryType: [
        {
          categoryName: 'bigquery',
          categoryType: 'databases',
          logo: 'assets/logos/bigquery/icon.svg',
        },
        {
          categoryName: 'clickhouse',
          categoryType: 'databases',
          logo: 'assets/logos/clickhouse/icon.svg',
        },
        {
          categoryName: 'cosmosdb',
          categoryType: 'databases',
          logo: 'assets/logos/cosmosdb/icon.svg',
        },
        {
          categoryName: 'couchdb',
          categoryType: 'databases',
          logo: 'assets/logos/couchdb/icon.svg',
        },
        {
          categoryName: 'dynamodb',
          categoryType: 'databases',
          logo: 'assets/logos/dynamodb/icon.svg',
        },
        {
          categoryName: 'elasticsearch',
          categoryType: 'databases',
          logo: 'assets/logos/elasticsearch/icon.svg',
        },
        {
          categoryName: 'firestore',
          categoryType: 'databases',
          logo: 'assets/logos/firestore/icon.svg',
        },
        {
          categoryName: 'influx',
          categoryType: 'databases',
          logo: 'assets/logos/influxdb/icon.svg',
        },
        {
          categoryName: 'mariadb',
          categoryType: 'databases',
          logo: 'assets/logos/mariadb/icon.svg',
        },
        {
          displayName: "MongoDB",
          categoryName: "mongodb",
          categoryType: "databases",
          logo: "assets/logos/mongodb/icon.svg",
          size: "25px",
        },
        {
          displayName: "MSSQL",
          categoryName: "mssql",
          categoryType: "databases",
          logo: "assets/logos/mssql/icon.svg",
        },
        {
          displayName: "MySQL",
          categoryName: "mysql",
          categoryType: "databases",
          logo: "assets/logos/mysql/icon.svg",
        },
        {
          categoryName: 'oracledb',
          categoryType: 'databases',
          logo: 'assets/logos/oracledb/icon.svg',
        },
        {
          displayName: "PostgreSQL",
          categoryName: "postgre",
          categoryType: "databases",
          logo: "assets/logos/postgresql/icon.svg",
        },
        {
          categoryName: 'redis',
          categoryType: 'databases',
          logo: 'assets/logos/redis/icon.svg',
        },
        {
          categoryName: 'rethinkdb',
          categoryType: 'databases',
          logo: 'assets/logos/rethinkdb/icon.svg',
        },
        {
          categoryName: 'saphana',
          categoryType: 'databases',
          logo: 'assets/logos/saphana/icon.svg',
        },
        {
          categoryName: 'snowflake',
          categoryType: 'databases',
          logo: 'assets/logos/snowflake/icon.svg',
        },
        {
          displayName: "Type Sense",
          categoryName: "typesensedb",
          categoryType: "databases",
          logo: "assets/logos/typesense/icon.svg",
        },
      ],
    },
  
    {
      id: 2,
      category: 'productivity apps',
      categoryType: [
        {
          categoryName: 'airtable',
          categoryType: 'productivityapps',
          logo: 'assets/logos/airtable/icon.svg',
        },
        {
          categoryName: 'amazonses',
          categoryType: 'productivityapps',
          logo: 'assets/logos/amazonses/icon.svg',
        },
        {
          categoryName: 'appwrite',
          categoryType: 'productivityapps',
          logo: 'assets/logos/appwrite/icon.svg',
        },
        {
          categoryName: 'athena',
          categoryType: 'productivityapps',
          logo: 'assets/logos/athena/icon.svg',
        },
        {
          categoryName: 'baserow',
          categoryType: 'productivityapps',
          logo: 'assets/logos/baserow/icon.svg',
        },
        {
          categoryName: 'mailgun',
          categoryType: 'productivityapps',
          logo: 'assets/logos/mailgun/icon.svg',
        },
        {
          categoryName: 'n8n',
          categoryType: 'productivityapps',
          logo: 'assets/logos/n8n/icon.svg',
        },
        {
          categoryName: 'notion',
          categoryType: 'productivityapps',
          logo: 'assets/logos/notion/icon.svg',
        },
        {
          categoryName: 'openapi',
          categoryType: 'productivityapps',
          logo: 'assets/logos/openapi/icon.svg',
        },
        {
          categoryName: 'restapi',
          categoryType: 'productivityapps',
          logo: 'assets/logos/restapi/icon.svg',
        },
        {
          categoryName: 'sendgrid',
          categoryType: 'productivityapps',
          logo: 'assets/logos/sendgrid/icon.svg',
        },
        {
          categoryName: 'smtp',
          categoryType: 'productivityapps',
          logo: 'assets/logos/smtp/icon.svg',
        },
        {
          categoryName: 'stripe',
          categoryType: 'productivityapps',
          logo: 'assets/logos/stripe/icon.svg',
        },
        {
          categoryName: 'twilio',
          categoryType: 'productivityapps',
          logo: 'assets/logos/twilio/icon.svg',
        },
        {
          categoryName: 'woocommerce',
          categoryType: 'productivityapps',
          logo: 'assets/logos/woocommerce/icon.svg',
        },
        {
          categoryName: 'zendesk',
          categoryType: 'productivityapps',
          logo: 'assets/logos/zendesk/icon.svg',
        },
        {
          categoryName: 'googlesheets',
          categoryType: 'productivityapps',
          logo: 'assets/logos/googlesheets/icon.svg',
        },
        {
          categoryName: 'slack',
          categoryType: 'productivityapps',
          logo: 'assets/logos/slack/icon.svg',
        },
        {
          categoryName: 'graphql',
          categoryType: 'productivityapps',
          logo: 'assets/logos/graphql/icon.svg',
        },
        {
          categoryName: 'grpc',
          categoryType: 'productivityapps',
          logo: 'assets/logos/grpc/icon.svg',
          size: "90px"
        },
      ]
    },
    {
      id: 3,
      category: 'cloud storage',
      categoryType: [
        {
          displayName: "Azure Blob Storage",
          categoryName: "azureblobstorage",
          categoryType: "cloudstorage",
          logo: "assets/logos/azureblobstorage/icon.svg",
        },
        {
          categoryName: 'gcs',
          categoryType: 'cloudstorage',
          logo: 'assets/logos/gcs/icon.svg',
        },
        {
          categoryName: 'minio',
          categoryType: 'cloudstorage',
          logo: 'assets/logos/minio/icon.svg',
        },
        {
          categoryName: 's3',
          categoryType: 'cloudstorage',
          logo: 'assets/logos/s3/icon.svg',
        },
      ]
    },
    {
      id: 4,
      category: 'plugins',
      categoryType: [
        {
          categoryName: 'openai',
          categoryType: 'plugins',
          logo: 'assets/logos/openai/icon.svg',
          size: "50px"
        },
        {
          categoryName: 'plivo',
          categoryType: 'plugins',
          logo: 'assets/logos/plivo/icon.svg',
          size: "90px"
        },
        {
          categoryName: 'github',
          categoryType: 'plugins',
          logo: 'assets/logos/github/icon.svg',
          size: "50px"
        },
        {
          categoryName: 'textract',
          categoryType: 'plugins',
          logo: 'assets/logos/textract/icon.svg',
          size: "50px"
        },
        {
          categoryName: 'harperdb',
          categoryType: 'plugins',
          logo: 'assets/logos/harperdb/icon.svg',
          size: "50px"
        },
      ]
    },
    {
      id: 5,
      category: 'ERP',
      categoryType: [
        {
          displayName: "SAP",
          categoryName: 'sap',
          categoryType: 'erp',
          logo: 'assets/logos/sap.png',
          size: "90px"
        },
        {
          categoryName: 'Oracle',
          categoryType: 'erp',
          logo: 'assets/logos/Oracle.png',
          size: "90px"
        },
        {
          categoryName: 'Epicor',
          categoryType: 'erp',
          logo: 'assets/logos/Epicor.png',
          size: "90px"
        },
        {
          categoryName: 'QAD',
          categoryType: 'erp',
          logo: 'assets/logos/qad/icon.svg',
          size: "90px"
        },
        {
          categoryName: 'NetSuite',
          categoryType: 'erp',
          logo: 'assets/logos/netsuite.png',
          size: "90px"
        },
        {
          displayName: "QuickBooks",
          categoryName: "quickbooks",
          categoryType: "erp",
          logo: "assets/logos/quickbooks.png",
          size: "50px",
        },
        {
          categoryName: 'ODOO',
          categoryType: 'erp',
          logo: 'assets/logos/odoo.svg',
          size: "90px"
        },
        {
          categoryName: 'Sage',
          categoryType: 'erp',
          logo: 'assets/logos/sage.svg',
          size: "70px"
        }
      ]
    },
    {
      id: 6,
      category: 'QMS',
      categoryType: [
        {
          categoryName: 'Safety Chain',
          categoryType: 'QMS',
          logo: 'assets/logos/safetychain/icon.jpg',
          size: "70px"
        },
        {
          categoryName: 'Trace Gains',
          categoryType: 'QMS',
          logo: 'assets/logos/tracegains/icon.png',
          size: "50px"
        },
        {
          categoryName: 'Food LogiQ',
          categoryType: 'QMS',
          logo: 'assets/logos/foodlogiq/icon.png',
          size: "70px"
        },
        {
          displayName: "MasterControl",
          categoryName: "mastercontrol",
          categoryType: "QMS",
          logo: "assets/logos/mastercontrol/icon.svg",
          size: "90px",
        },
        {
          categoryName: 'dot compliance',
          categoryType: 'QMS',
          logo: 'assets/logos/dotcompliance/icon.jpg',
          size: "70px"
        },
        {
          categoryName: '1 factory',
          categoryType: 'QMS',
          logo: 'assets/logos/1factory/icon.png',
          size: "90px"
        },
        {
          categoryName: 'compliance quest',
          categoryType: 'QMS',
          logo: 'assets/logos/compliancequest/icon.jpg',
          size: "50px"
        }
      ]
    },
    {
      id: 7,
      category: 'MES',
      categoryType: [
        {
          displayName: 'MBRAIN',
          categoryName: 'mbrain',
          categoryType: 'mes',
          logo: 'assets/logos/MBrain.svg',
          size: "90px"
        },
        {
          categoryName: 'plex',
          categoryType: 'mes',
          logo: 'assets/logos/plex/icon.svg',
          size: "90px"
        },
        {
          displayName: "Tulip",
          categoryName: "tulip",
          categoryType: "mes",
          logo: "assets/logos/tulip/icon.svg",
          size: "50px",
        },
      ]
    },
    {
      id: 8,
      category: 'HRMS',
      categoryType: [
        {
          displayName: "ADP",
          categoryName: "adp",
          categoryType: "hrms",
          logo: "assets/logos/adp.png",
          size: "90px",
        },
        {
          categoryName: 'Kronos',
          categoryType: 'hrms',
          logo: 'assets/logos/kronos.png',
          size: "90px"
        },
        {
          categoryName: 'Workday',
          categoryType: 'hrms',
          logo: 'assets/logos/workday.png',
          size: "90px"
        },
        {
          categoryName: 'SAP successfactors',
          categoryType: 'hrms',
          logo: 'assets/logos/sap.png',
          size: "80px"
        },
        {
          categoryName: 'TalentHub',
          categoryType: 'hrms',
          logo: 'assets/logos/talenthub.png',
          size: "50px"
        },
        {
          categoryName: 'PayCom',
          categoryType: 'hrms',
          logo: 'assets/logos/paycom.png',
          size: "90px"
        },
      ]
    },
    {
      id: 9,
      category: 'CRM',
      categoryType: [
        {
          displayName:'Salesforce',
          categoryName: 'salesforce',
          categoryType: 'crm',
          logo: 'assets/logos/Salesforce.png',
          size: "70px"
        },
        {
          categoryName: 'Hubspot',
          categoryType: 'crm',
          logo: 'assets/logos/Hubspot.png',
          size: "90px"
        },
      ]
    },
    {
      id: 10,
      category: 'Shipping',
      categoryType: [
        {
          categoryName: 'USPS',
          categoryType: 'shipping',
          logo: 'assets/logos/usps.svg',
          size: "90px"
        },
        {
          displayName: "FedEx",
          categoryName: "fedex",
          categoryType: "shipping",
          logo: "assets/logos/fedex.svg",
          size: "90px",
        },
        {
          categoryName: 'Amazon',
          categoryType: 'shipping',
          logo: 'assets/logos/amazon.svg',
          size: "90px"
        },
        {
          categoryName: 'UPS',
          categoryType: 'shipping',
          logo: 'assets/logos/ups.svg',
          size: "90px"
        },
        {
          categoryName: 'DHL',
          categoryType: 'shipping',
          logo: 'assets/logos/dhl.png',
          size: "90px"
        },
        {
          categoryName: 'ShipStation',
          categoryType: 'shipping',
          logo: 'assets/logos/shipstation.png',
          size: "90px"
        }
      ]
    },
    {
      id: 11,
      category: 'Files',
      categoryType: [
        {
          categoryName: 'Word',
          categoryType: 'files',
          logo: 'assets/logos/word.svg',
          size: "90px"
        },
        {
          categoryName: 'Pdf',
          categoryType: 'files',
          logo: 'assets/logos/pdf.png',
          size: "50px"
        },
        {
          categoryName: 'Excel',
          categoryType: 'files',
          logo: 'assets/logos/excel.png',
          size: "50px"
        }
      ]
    },
    {
      id: 12,
      category: 'industrial systems',
      categoryType: [
        {
          categoryName: 'MTConnect',
          categoryType: 'industrialsystems',
          logo: 'assets/logos/industrial/mtconnect.png',
          size: "90px"
        },
        {
          categoryName: 'OPCUA',
          categoryType: 'industrialsystems',
          logo: 'assets/logos/industrial/opcua.png',
          size: "90px"
        },
        {
          categoryName: 'Kepware',
          categoryType: 'industrialsystems',
          logo: 'assets/logos/industrial/kepware.svg',
          size: "90px"
        },
        {
          categoryName: 'HiveMQ',
          categoryType: 'industrialsystems',
          logo: 'assets/logos/industrial/hivemq.svg',
          size: "90px"
        },
        {
          categoryName: 'Modbus',
          categoryType: 'industrialsystems',
          logo: 'assets/logos/industrial/modbus.png',
          size: "50px"
        },
        {
          categoryName: 'Ignition',
          categoryType: 'industrialsystems',
          logo: 'assets/logos/industrial/ignition.png',
          size: "90px"
        },
        {
          categoryName: 'Siemens',
          categoryType: 'industrialsystems',
          logo: 'assets/logos/industrial/siemens.svg',
          size: "90px"
        },
        {
          categoryName: 'Rockwell Automation',
          categoryType: 'industrialsystems',
          logo: 'assets/logos/industrial/rockwell.svg',
          size: "70px"
        },
        {
          categoryName: 'FactoryTalk',
          categoryType: 'industrialsystems',
          logo: 'assets/logos/industrial/factorytalk.svg',
          size: "90px"
        },
        {
          categoryName: 'OSIsoft',
          categoryType: 'industrialsystems',
          logo: 'assets/logos/industrial/osisoft.png',
          size: "90px"
        },
        {
          displayName: "Neuclon Edge",
          categoryName: 'neuclonedge',
          categoryType: 'industrialsystems',
          logo: 'assets/logos/industrial/neuclonedge.svg',
          size: "60px"
        }
      ]
    },
    {
      id: 13,
      category: 'test & inspection',
      categoryType: [
        {
          categoryName: 'Zeiss',
          categoryType: 'test',
          logo: 'assets/logos/test/zeiss.svg',
          size: "50px"
        },
        {
          categoryName: 'Keyence',
          categoryType: 'test',
          logo: 'assets/logos/test/keyence.svg',
          size: "90px"
        },
        {
          categoryName: 'Faro',
          categoryType: 'test',
          logo: 'assets/logos/test/faro.svg',
          size: "90px"
        },
        {
          categoryName: 'Mitutoyo',
          categoryType: 'test',
          logo: 'assets/logos/test/mitutoyo.svg',
          size: "90px"
        },
        {
          categoryName: 'Atlas Copco',
          categoryType: 'test',
          logo: 'assets/logos/test/atlas.svg',
          size: "90px"
        },
  
      ]
    },
    {
      id: 14,
      category: 'robotics',
      categoryType: [
        {
          categoryName: 'Yaskawa',
          categoryType: 'robotics',
          logo: 'assets/logos/robotics/yaskawa.svg',
          size: "90px"
        },
        {
          categoryName: 'Staubli',
          categoryType: 'robotics',
          logo: 'assets/logos/robotics/staubli.svg',
          size: "90px"
        },
        {
          categoryName: 'ABB',
          categoryType: 'robotics',
          logo: 'assets/logos/robotics/abb.svg',
          size: "90px"
        },
        {
          categoryName: 'Epson',
          categoryType: 'robotics',
          logo: 'assets/logos/robotics/epson.png',
          size: "90px"
        },
        {
          categoryName: 'Fanuc',
          categoryType: 'robotics',
          logo: 'assets/logos/robotics/fanuc.jpeg',
          size: "90px"
        },
        {
          categoryName: 'Kawasaki',
          categoryType: 'robotics',
          logo: 'assets/logos/robotics/kawasaki.svg',
          size: "90px"
        },
        {
          categoryName: 'Ready Robotics',
          categoryType: 'robotics',
          logo: 'assets/logos/robotics/ready.png',
          size: "90px"
        },
        {
          categoryName: 'Universal Robots',
          categoryType: 'robotics',
          logo: 'assets/logos/robotics/universal.png',
          size: "40px"
        },
        {
          categoryName: 'Kuka',
          categoryType: 'robotics',
          logo: 'assets/logos/robotics/kuka.svg',
          size: "90px"
        }
      ]
    },
    {
      id: 15,
      category: 'IOT Sensors',
      categoryType: [
        {
          categoryName: 'Alien',
          categoryType: 'IOT',
          logo: 'assets/logos/IOT/alien.svg',
          size: "50px"
        },
        {
          categoryName: 'Caen RFID',
          categoryType: 'IOT',
          logo: 'assets/logos/IOT/caenrifid.svg',
          size: "90px"
        },
        {
          categoryName: 'Avery Dennison',
          categoryType: 'IOT',
          logo: 'assets/logos/IOT/avery.svg',
          size: "90px"
        },
        {
          categoryName: 'Fujitsu',
          categoryType: 'IOT',
          logo: 'assets/logos/IOT/fujitsu.svg',
          size: "90px"
        },
        {
          categoryName: 'Honeywell',
          categoryType: 'IOT',
          logo: 'assets/logos/IOT/honeywell.svg',
          size: "90px"
        },
        {
          categoryName: 'HID',
          categoryType: 'IOT',
          logo: 'assets/logos/IOT/hid.svg',
          size: "90px"
        }
      ]
    },
    {
      id: 16,
      category: 'Ecommerce',
      categoryType: [
        {
          categoryName: 'Ebay',
          categoryType: 'ecommerce',
          logo: 'assets/logos/ecommerce/ebay.png',
          size: "90px"
        },
        {
          categoryName: 'Shopify',
          categoryType: 'ecommerce',
          logo: 'assets/logos/ecommerce/shopify.png',
          size: "90px"
        },
        {
          categoryName: 'Amazon',
          categoryType: 'ecommerce',
          logo: 'assets/logos/ecommerce/amazon.png',
          size: "90px"
        },
        {
          categoryName: 'WayFair',
          categoryType: 'ecommerce',
          logo: 'assets/logos/ecommerce/wayfair.png',
          size: "90px"
        }
      ]
    },
    {
      id: 17,
      category: 'Social Network',
      categoryType: [
        {
          categoryName: 'X',
          categoryType: 'socialNetwork',
          logo: 'assets/logos/socialnetwork/x.png',
          size: "90px"
        },
        {
          categoryName: 'Instagram',
          categoryType: 'socialNetwork',
          logo: 'assets/logos/socialnetwork/instagram.png',
          size: "50px"
        },
        {
          categoryName: 'Facebook',
          categoryType: 'socialNetwork',
          logo: 'assets/logos/socialnetwork/facebook.png',
          size: "50px"
        },
        {
          categoryName: 'Linkedin',
          categoryType: 'socialNetwork',
          logo: 'assets/logos/socialnetwork/linkedin.png',
          size: "50px"
        },
        {
          categoryName: 'TikTok',
          categoryType: 'socialNetwork',
          logo: 'assets/logos/socialnetwork/tiktok.png',
          size: "50px"
        }
      ]
    },
    {
      id: 18,
      category: 'Search',
      categoryType: [
        {
          categoryName: 'Google Analytics',
          categoryType: 'search',
          logo: 'assets/logos/search/googleanalytics.png',
          size: "90px"
        },
        {
          categoryName: 'Google Ads',
          categoryType: 'search',
          logo: 'assets/logos/search/googleads.png',
          size: "90px"
        }
      ]
    },
    {
      id: 12,
      category: "EHS",
      categoryType: [
        {
          categoryName: "VelocityEHS",
          categoryType: "ehs",
          logo: "assets/logos/velocityEHS.png",
          size: "90px",
        },
      ],
    },
  ].slice().sort((a, b) => a.category.localeCompare(b.category));

  export default logoData;