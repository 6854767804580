<section class="d-ai-applications-section ptb-110">
    <div class="d-ai-applications-main-container">
        <div class="d-ai-applications-title-container">
            <h2>AI-powered Applications</h2>
            <p>Out of the Box</p>
            <div></div>
        </div>
        <div class="d-ai-applications-item-container">
            <div *ngFor="let application of applications | 
             paginate:{
                itemsPerPage:itemsPerPage,
                currentPage: currentPage,
                totalItems: totalItems
             }
            " class="d-ai-applications-item d-ai-toggle-pagination">
                <img src="{{application.image}}" alt="">
                <div>
                    <h3>{{application.title}}</h3>
                    <p>{{application.content}}</p>
                </div>
            </div>
            <pagination-controls class="my-pagination d-ai-toggle-pagination" (pageChange)="currentPage = $event"></pagination-controls>
            
            <div class="ai-applications-slides owl-carousel owl-theme d-ai-toggle-carousel">
                <div *ngFor="let application of applications" class="d-ai-applications-carousel-item">
                    <div class="d-aia-carousel-img" style="background-image: url({{application.image}});">
                        <!-- <img [src]="application.image" [alt]="application.title" /> -->
                    </div>
                    <div>
                        <h3>{{application.title}}</h3>
                        <p>{{application.content}}</p>
                    </div>
                </div> 
            </div>

        </div>

    </div>

</section>
