<div class="home-features-container">
    <div class="d-home-features">
        <div *ngFor="let featureItem of homeFeaturesData" class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <i class="{{featureItem.icon}}"></i>
                <div class="d-home-feature-text-container">
                    <h3>{{featureItem.front.title}}</h3>
                    <p>{{featureItem.front.sub}}</p>
                </div>
              </div>
              <div class="flip-card-back">
                <p>{{featureItem.back.title}}</p>
              </div>
            </div>
        </div>
    </div>
</div>