import { Component } from "@angular/core";

@Component({
    selector: "app-three-d-model",
    templateUrl: "./three-d-model.component.html",
    styleUrls: ["./three-d-model.component.scss"],
})
export class ThreeDModelComponent {
    scale: number = 1;
    panning: boolean = false;
    pointX: number = 0;
    pointY: number = 0;
    start: { x: number; y: number } = { x: 0, y: 0 };
    zoom: HTMLElement;

    maxZoomIn: number = 2;
    maxZoomOut: number = 1;

    showResetButton: boolean = false;

    ctrlKeyPressed: boolean = false;

    iconUrl: string = "../../../assets/img/plus.png";

    icon1: boolean = true;
    icon2: boolean = false;
    icon3: boolean = false;
    icon4: boolean = false;
    icon5: boolean = false;
    icon6: boolean = false;
    icon7: boolean = false;
    icon8: boolean = false;
    icon9: boolean = false;
    icon10: boolean = false;
    icon11: boolean = false;
    icon12: boolean = false;
    icon13: boolean = false;
    icon14: boolean = false;

    constructor() {
        document.addEventListener("keydown", this.handleKeyDown.bind(this));
        document.addEventListener("keyup", this.handleKeyUp.bind(this));
    }

    handleKeyDown(event: KeyboardEvent) {
        // Check if the Ctrl key is pressed
        if (event.ctrlKey) {
            this.ctrlKeyPressed = true;
        }
    }

    handleKeyUp(event: KeyboardEvent) {
        // Check if the Ctrl key is released
        if (!event.ctrlKey) {
            this.ctrlKeyPressed = false;
        }
    }

    ngOnInit() {
        this.zoom = document.getElementById("zoom");

        // call when we grab model
        this.zoom.onmousedown = (e: MouseEvent) => {
            e.preventDefault();

            this.start = {
                x: e.clientX - this.pointX,
                y: e.clientY - this.pointY,
            };
            this.panning = false;
            this.isResetVisible();
        };

        // call when we leave model
        this.zoom.onmouseup = (e: MouseEvent) => {
            this.panning = false;
            this.isResetVisible();
        };

        this.zoom.onmousemove = (e: MouseEvent) => {
            // e.preventDefault();
            if (!this.panning) {
                return;
            }

            this.pointX = e.clientX - this.start.x;
            this.pointY = e.clientY - this.start.y;
            this.setTransform();
            this.isResetVisible();
        };

        this.zoom.onwheel = (e: WheelEvent | any) => {
            if (this.ctrlKeyPressed) {
                e.preventDefault();
                const xs = (e.clientX - this.pointX) / this.scale;
                const ys = (e.clientY - this.pointY) / this.scale;
                const delta = e.wheelDelta ? e.wheelDelta : -e.deltaY;

                // Apply zoom limits
                if (delta > 0 && this.scale < this.maxZoomIn) {
                    this.scale *= 1.2;
                } else if (delta < 0 && this.scale > this.maxZoomOut) {
                    this.scale /= 1.2;
                }

                this.pointX = e.clientX - xs * this.scale;
                this.pointY = e.clientY - ys * this.scale;
                this.setTransform();
                this.isResetVisible();
            }
        };
    }

    setTransform() {
        this.zoom.style.transform = `translate(${this.pointX}px, ${this.pointY}px) scale(${this.scale})`;
        this.isResetVisible();
    }

    isResetVisible() {
        if (this.scale == 1 && this.pointX == 0 && this.pointY == 0) {
            this.showResetButton = false;
        } else {
            this.showResetButton = true;
        }
    }

    resetZoom(): void {
        this.zoom.style.transform = `translate(0px, 0px) scale(1)`;
        this.showResetButton = false;
        this.icon1 = true;
        this.icon2 = false;
        this.icon3 = false;
        this.icon4 = false;
        this.icon5 = false;
        this.icon6 = false;
        this.icon7 = false;
        this.icon8 = false;
        this.icon9 = false;
        this.icon10 = false;
        this.icon11 = false;
        this.icon12 = false;
        this.icon13 = false;
        this.icon14 = false;
    }

    icon1False() {
        this.icon1 = false;
    }

    resetIcons() {
        this.icon1 = false;
        this.icon2 = false;
        this.icon3 = false;
        this.icon4 = false;
        this.icon5 = false;
        this.icon6 = false;
        this.icon7 = false;
        this.icon8 = false;
        this.icon9 = false;
        this.icon10 = false;
        this.icon11 = false;
        this.icon12 = false;
        this.icon13 = false;
        this.icon14 = false;
    }
}
