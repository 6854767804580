<section class="services-section bg-white ptb-110 center body-3d">
    <div
        *ngIf="showResetButton"
        class="btn-box btn-box-getStarted mt-4 resetViewBtn"
    >
        <a (click)="resetZoom()" class="btn btn-primary">Reset View</a>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12 col-sm-12 align-self-center">
                <h1 class="fontBold">Go beyond the shop floor</h1>
                <p>From the shop floor to the C-Suite, we give everyone on your team the information and insights they need for their specific job,
                    on a single platform.
                    </p>
                <a href="/contact" class="btn btn-primary me-2">
                    Get started
                </a>
            </div>
            <div class="col-lg-9 col-md-12 col-sm-12">
                <div class="zoom_outer">
                    <div id="zoom">
                        <!-- Productivity Management  -->
                        <div *ngIf="!showResetButton" class="icon-wrapper1">
                            <img
                                class="icon"
                                src="{{ iconUrl }}"
                                alt="icon not found"
                                (mouseover)="icon1 = true"
                                (mouseout)="resetIcons()"
                            />
                            <div class="text" *ngIf="icon1">
                                <h6 class="center fontBold">Productivity Management</h6>
                                <p class="iconPara">
                                    Efficiently track, analyze, and optimize
                                    productivity for streamlined business operations.
                                </p>
                            </div>
                        </div>
                        <!-- Production Scheduling  -->
                        <div *ngIf="!showResetButton" class="icon-wrapper2">
                            <img
                                class="icon"
                                src="{{ iconUrl }}"
                                alt="icon not found"
                                (mouseover)="icon2 = true; icon1False()"
                                (mouseout)="resetIcons()"
                            />
                            <div class="text" *ngIf="icon2">
                                <h6 class="center fontBold">Production Scheduling</h6>
                                <p class="iconPara">
                                    Optimize production schedules for maximum efficiency
                                    and on-time delivery.
                                </p>
                            </div>
                        </div>
                        <!-- Supply Chain Management-->
                        <div *ngIf="!showResetButton" class="icon-wrapper3">
                            <img
                                class="icon"
                                src="{{ iconUrl }}"
                                alt="icon not found"
                                (mouseover)="icon3 = true; icon1False()"
                                (mouseout)="resetIcons()"
                            />
                            <div class="text" *ngIf="icon3">
                                <h6 class="center fontBold">Supply Chain Management</h6>
                                <p class="iconPara">
                                    Gain complete visibility into your supply chain for
                                    informed decision-making.
                                </p>
                            </div>
                        </div>
                        <!-- Automated Mobile Robot-->
                        <div *ngIf="!showResetButton" class="icon-wrapper4">
                            <img
                                class="icon"
                                src="{{ iconUrl }}"
                                alt="icon not found"
                                (mouseover)="icon4 = true; icon1False()"
                                (mouseout)="resetIcons()"
                            />
                            <div class="text" *ngIf="icon4">
                                <h6 class="center fontBold">Automated Mobile Robot</h6>
                                <p class="iconPara">
                                    Effortlessly connect and orchestrate automated
                                    mobile robots for efficient operations.
                                </p>
                            </div>
                        </div>
                        <!-- Manufacturing Execution-->
                        <div *ngIf="!showResetButton" class="icon-wrapper5">
                            <img
                                class="icon"
                                src="{{ iconUrl }}"
                                alt="icon not found"
                                (mouseover)="icon5 = true; icon1False()"
                                (mouseout)="resetIcons()"
                            />
                            <div class="text" *ngIf="icon5">
                                <h6 class="center fontBold">Manufacturing Execution</h6>
                                <p class="iconPara">
                                    Streamline and optimize your manufacturing processes
                                    with an advanced execution system.
                                </p>
                            </div>
                        </div>
                        <!-- Quality Management-->
                        <div *ngIf="!showResetButton" class="icon-wrapper6">
                            <img
                                class="icon"
                                src="{{ iconUrl }}"
                                alt="icon not found"
                                (mouseover)="icon6 = true; icon1False()"
                                (mouseout)="resetIcons()"
                            />
                            <div class="text" *ngIf="icon6">
                                <h6 class="center fontBold">Quality Management</h6>
                                <p class="iconPara">
                                    Integrate intelligence into quality management for
                                    enhanced product excellence and compliance.
                                </p>
                            </div>
                        </div>
                        <!-- Connected Workforce-->
                        <div *ngIf="!showResetButton" class="icon-wrapper7">
                            <img
                                class="icon"
                                src="{{ iconUrl }}"
                                alt="icon not found"
                                (mouseover)="icon7 = true; icon1False()"
                                (mouseout)="resetIcons()"
                            />
                            <div class="text" *ngIf="icon7">
                                <h6 class="center fontBold">Connected Workforce</h6>
                                <p class="iconPara">
                                    Empower your workforce with work instructions,
                                    performance feedback, productivity tools, and
                                    collaborative platforms for enhanced efficiency.
                                </p>
                            </div>
                        </div>
                        <!-- Operations Management-->
                        <div *ngIf="!showResetButton" class="icon-wrapper8">
                            <img
                                class="icon"
                                src="{{ iconUrl }}"
                                alt="icon not found"
                                (mouseover)="icon8 = true; icon1False()"
                                (mouseout)="resetIcons()"
                            />
                            <div class="text" *ngIf="icon8">
                                <h6 class="center fontBold">Operations Management</h6>
                                <p class="iconPara">
                                    Improve profitability with accurate forecasting and
                                    effective operations management strategies.
                                </p>
                            </div>
                        </div>
                        <!-- Automated Guided Vehicle-->
                        <div *ngIf="!showResetButton" class="icon-wrapper9">
                            <img
                                class="icon"
                                src="{{ iconUrl }}"
                                alt="icon not found"
                                (mouseover)="icon9 = true; icon1False()"
                                (mouseout)="resetIcons()"
                            />
                            <div class="text" *ngIf="icon9">
                                <h6 class="center fontBold">
                                    Automated Guided Vehicle
                                </h6>
                                <p class="iconPara">
                                    Maximize operational efficiency through intelligent
                                    AGV systems, optimizing material flow and enhancing
                                    productivity in your facility.
                                </p>
                            </div>
                        </div>
                        <!-- Daily Management-->
                        <div *ngIf="!showResetButton" class="icon-wrapper10">
                            <img
                                class="icon"
                                src="{{ iconUrl }}"
                                alt="icon not found"
                                (mouseover)="icon10 = true; icon1False()"
                                (mouseout)="resetIcons()"
                            />
                            <div class="text" *ngIf="icon10">
                                <h6 class="center fontBold">Daily Management</h6>
                                <p class="iconPara">
                                    Boost manufacturing daily management with analytics:
                                    data-driven decisions, optimized resource
                                    allocation, and enhanced productivity.
                                </p>
                            </div>
                        </div>
                        <!-- Robotic & Process Automation-->
                        <div *ngIf="!showResetButton" class="icon-wrapper11">
                            <img
                                class="icon"
                                src="{{ iconUrl }}"
                                alt="icon not found"
                                (mouseover)="icon11 = true; icon1False()"
                                (mouseout)="resetIcons()"
                            />
                            <div class="text" *ngIf="icon11">
                                <h6 class="center fontBold">
                                    Robotic & Process Automation
                                </h6>
                                <p class="iconPara">
                                    Drive productivity and safety with integrated
                                    collaborative robots, automating processes for
                                    increased efficiency, productivity, and workplace
                                    safety.
                                </p>
                            </div>
                        </div>
                        <!-- Machine Monitoring-->
                        <div *ngIf="!showResetButton" class="icon-wrapper12">
                            <img
                                class="icon"
                                src="{{ iconUrl }}"
                                alt="icon not found"
                                (mouseover)="icon12 = true; icon1False()"
                                (mouseout)="resetIcons()"
                            />
                            <div class="text" *ngIf="icon12">
                                <h6 class="center fontBold">Machine Monitoring</h6>
                                <p class="iconPara">
                                    Enhance machine monitoring with advanced condition
                                    and usage monitoring, leveraging sensors for
                                    improved Overall Equipment Efficiency (OEE).
                                </p>
                            </div>
                        </div>
                        <!-- C-Suite-->
                        <div *ngIf="!showResetButton" class="icon-wrapper13">
                            <img
                                class="icon"
                                src="{{ iconUrl }}"
                                alt="icon not found"
                                (mouseover)="icon13 = true; icon1False()"
                                (mouseout)="resetIcons()"
                            />
                            <div class="text" *ngIf="icon13">
                                <h6 class="center fontBold">Accounting and Finance</h6>
                                <p class="iconPara">
                                    Integrate ERP, CRM with MES for seamless data flow, 
                                    enhanced financial analysis, streamlined order-to-cash, 
                                    and improved cost control and forecasting.
                                </p>
                            </div>
                        </div>
                        <!-- Another-->
                        <div *ngIf="!showResetButton" class="icon-wrapper14">
                            <img
                                class="icon"
                                src="{{ iconUrl }}"
                                alt="icon not found"
                                (mouseover)="icon14 = true; icon1False()"
                                (mouseout)="resetIcons()"
                            />
                            <div class="text" *ngIf="icon14">
                                <h6 class="center fontBold">C-Suite</h6>
                                <p class="iconPara">
                                    Get a 360º real-time view of your operations, make better informed decisions, and uncover hidden opportunities
                                </p>
                            </div>
                        </div>
                        <img src="../../../assets/img/IMG_3D_4.png" alt="zoom" />
                    </div>
                </div>
            </div>
            
        </div>   
    </div>
</section>
