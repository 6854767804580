<!-- <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let slide of processedData" class="custom-owl-container">
      <ng-template carouselSlide [id]="slide.id">
        <img [src]="slide.src" [alt]="slide.alt" [title]="slide.title">
      </ng-template>
    </ng-container>
  </owl-carousel-o> -->
<div class="integration-container">
    <!-- <p class="integration-title">Integrations</p> -->
    <div
    class="slider-container"
    >
      <ng-image-slider
        #nav
        [images]="processedData"
        [infinite]="true"
        [autoSlide]="true"
        [imageSize]="{ width: '50px', height: '30px' }"
        [animationSpeed]="0.5"
        [arrowKeyMove]="false"
        [showArrow]="false"
        [imagePopup]="false"
        slideImage="1"
      ></ng-image-slider>
    </div>
</div>