import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AboutComponent } from "./components/pages/about/about.component";
import { NotFoundComponent } from "./components/pages/not-found/not-found.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { HomeFourComponent } from "./components/pages/home-four/home-four.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { Blog1Component } from "./components/pages/blog1/blog1.component";
import { Blog2Component } from "./components/pages/blog2/blog2.component";
import { Blog3Component } from "./components/pages/blog3/blog3.component";

const routes: Routes = [
    { path: "", component: HomeFourComponent },
    { path: "about", component: AboutComponent },
    { path: "blog-details", component: BlogDetailsComponent },
    { path: "blog-details/1", component: Blog1Component },
    { path: "blog-details/2", component: Blog2Component },
    { path: "blog-details/3", component: Blog3Component },
    { path: "contact", component: ContactComponent },
    // Here add new pages component

    { path: "**", component: NotFoundComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
