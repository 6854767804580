import { Component } from '@angular/core';

@Component({
  selector: 'app-blog3',
  templateUrl: './blog3.component.html',
  styleUrls: ['./blog3.component.scss']
})
export class Blog3Component {

}
