<section class="services-area bg-f2f6f9 ptb-110 onlyBlueBackground">
    <div class="container">
        <div class="row">
            <h1 class="onlyWhiteColor fontBold">Let's see how it works</h1>
        </div>
        <div class="container row onlyWhiteColor">
            <div class="col-lg-5 col-md-12 col-sm-12">
                <ul>
                    <!-- <li class="custom-icon-li"> -->
                    <li>
                        <div class="mt-4">
                            <!-- <span class="tabs-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-eye-fill"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"
                            />
                            <path
                                d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                            />
                        </svg>
                    </span> -->
                            <h4 class="onlyWhiteColor fontBold">
                                What do you want to do?
                            </h4>

                            <p class="mt-2 text-white fontSize15">
                                Use our interactive wizard to define data needs.
                                This enables solution driven features customized
                                for your needs.
                            </p>
                        </div>
                    </li>

                    <li>
                        <div class="mt-4">
                            <h4 class="onlyWhiteColor fontBold">
                                Connect data sources
                            </h4>

                            <p class="mt-2 text-white fontSize15">
                                Bring data from multiple sources-Machines, Test
                                and Inspection devices, Legacy spreadseets,
                                CRMs, ERPs, IoT Devices etc - to create a single
                                data lake.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="mt-4">
                            <h4 class="onlyWhiteColor fontBold">
                                Create a Workspace
                            </h4>

                            <p class="mt-2 text-white fontSize15">
                                Create custom Workspace. Use interactive
                                AI-powered tools to create data models, trends
                                and critical business intellgence you need. From
                                no code-click, drag and drop- to advance
                                scripting capabilities.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="mt-4">
                            <h4 class="onlyWhiteColor fontBold">Get insight</h4>

                            <p class="mt-2 text-white fontSize15">
                                Unify data from many sources to create
                                interactive, immersive dashboards and reports
                                that provide actionable insights and drive
                                business results.
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <div
                class="col-lg-7 col-md-12 col-sm-12 d-flex justify-content-center align-items-center"
            >
                <div class="container">
                    <img
                        class="mt-4"
                        src="../../../assets/img/slide5.jpg"
                        alt="image not found"
                    />
                </div>
            </div>
        </div>
        <div class="btn-box btn-box-getStarted mt-4">
            <a routerLink="/contact" class="btn btn-getStarted">Try for free</a>
        </div>
    </div>

    <div class="shape-img3">
        <img src="assets/img/shape/shape3.png" alt="image" />
    </div>
    <div class="shape-img2">
        <img src="assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="assets/img/shape/shape5.svg" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="assets/img/shape/shape4.svg" alt="image" />
    </div>
    <div class="dot-shape1">
        <img src="assets/img/shape/dot1.png" alt="image" />
    </div>
    <div class="dot-shape2">
        <img src="assets/img/shape/dot3.png" alt="image" />
    </div>
</section>
