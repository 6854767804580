<!-- Start Page Title Area -->
<div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-110">
    <div class="container">
        <div class="section-title">
            <span>Message Us</span>
            <h2>Let's get you started</h2>
            <p>
                Please provide the following details along with your message so
                we may appropriately assist you. we will protect your personal
                information in accordance with our Privacy Statement.
            </p>
        </div>

        <div class="contact-form">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="contact-image">
                        <img
                            src="../../../../assets/img/contact-is.png"
                            alt="image"
                        />
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form
                        [formGroup]="contactForm"
                        (ngSubmit)="submit()"
                        id="contactForm"
                    >
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        class="form-control"
                                        required
                                        placeholder="First Name"
                                        formControlName="name"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        class="form-control"
                                        required
                                        placeholder="Last Name"
                                        formControlName="lastName"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        class="form-control"
                                        required
                                        placeholder="Business Email"
                                        formControlName="businessEmail"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="phone_number"
                                        id="phone_number"
                                        required
                                        class="form-control"
                                        placeholder="Phone Number"
                                        formControlName="phoneNumber"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="msg_subject"
                                        id="msg_subject"
                                        class="form-control"
                                        placeholder="Job Title"
                                        formControlName="jobTitle"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="role_organization"
                                        id="role_organization"
                                        class="form-control"
                                        placeholder="organization"
                                        formControlName="organization"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <select
                                        formControlName="ProjectTiming"
                                        class="form-control"
                                    >
                                        <option value="" disabled>
                                            Select Project Timing
                                        </option>

                                        <option
                                            value="
                                            Less Then 1 Months"
                                        >
                                            Less Then 1 Months
                                        </option>
                                        <option value="3 Months">
                                            3 Months
                                        </option>
                                        <option value="6 Months">
                                            6 Months
                                        </option>
                                        <option
                                            value="
                                            More than  6 Months"
                                        >
                                            More than 6 Months
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <select
                                        formControlName="country"
                                        class="form-control"
                                        placeholder="Select Country"
                                        name="country"
                                    >
                                        <option value="" disabled>
                                            Select Country
                                        </option>

                                        <option
                                            *ngFor="let country of countries"
                                            [value]="country"
                                        >
                                            {{ country }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="msg_subject"
                                        id="msg_subject"
                                        class="form-control"
                                        placeholder="Description"
                                        formControlName="description"
                                    />
                                </div>
                            </div>

                            <p class="responseMessage">
                                {{ responseMessage && responseMessage }}
                            </p>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="contact-info">
            <div class="contact-info-content">
                <h3>Contact us by Email Address</h3>
                <h2>
                    <a href="mailto:info@singleinsight.io"
                        >info@singleinsight.io</a
                    >
                </h2>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->
