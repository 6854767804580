import { Component } from "@angular/core";

@Component({
    selector: 'app-single-insight-advantage',
    templateUrl: './single-insight-advantage.component.html',
    styleUrls: ['./single-insight-advantage.component.scss']
})
export class SingleInsightAdvantageComponent{

    cardData:any = [
        {
            title:" We’re Manufacturing Experts",
            sub:"Not just experts in software and AI",
            back: "You really want a partner who understands your manufacturing operations and challenges"
        },
        {
            title:" We Assess Your Operations and Needs",
            sub:"We work with you to determine the best solution",
            back:"Let's determine your greatest needs, and develop an implementation plan that works best for you"
        },
        {
            title:"Easy Implementation",
            sub:" Minimal disruption to your operations",
            back:"With our user-friendly interface and plug-and-play modules, minimal training is required"
        },
        {
            title:"Outstanding Support",
            sub:"We provide support every step of the way",
            back:"We'll help you maximize the benefits"
        },
    ];
}