import { Component } from '@angular/core';

@Component({
  selector: 'app-three-d-text',
  templateUrl: './three-d-text.component.html',
  styleUrls: ['./three-d-text.component.scss']
})
export class ThreeDTextComponent {

}
