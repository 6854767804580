<!-- Start Free Trial Area -->
<section class="free-trial-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="free-trial-image">
                <img src="assets/img/woman2.jpg" alt="image" />
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="free-trial-content">
                <h2>Sign up for Free Trial</h2>
                <form
                    [formGroup]="subscriptionForm"
                    (ngSubmit)="submit()"
                    class="newsletter-form"
                >
                    <input
                        type="email"
                        id="placeholderColor"
                        class="input-newsletter"
                        placeholder="Enter your business email here"
                        formControlName="email"
                    />
                    <button type="submit">Sign Up Free</button>
                </form>
                <p>Find the solution that’s right for you!</p>
            </div>
        </div>
    </div>
</section>
<!-- End Free Trial Area -->
