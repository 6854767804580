<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Page Title Area -->

<app-about-area></app-about-area>

<app-three-d-text></app-three-d-text>
<div id="threedModelAbout">
    <app-three-d-model></app-three-d-model>
</div>

<!-- Start Team Area -->
<!-- <section class="team-area ptb-110">
    <div class="container">
        <div class="section-title">
            <h2>Meet Our Team</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team1.jpg" alt="team" />
                        <div class="social">
                            <a href="#" target="_blank"
                                ><i class="fab fa-facebook-f"></i
                            ></a>
                            <a href="#" target="_blank"
                                ><i class="fab fa-twitter"></i
                            ></a>
                            <a href="#" target="_blank"
                                ><i class="fab fa-instagram"></i
                            ></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>John Smith</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team2.jpg" alt="team" />
                        <div class="social">
                            <a href="#" target="_blank"
                                ><i class="fab fa-facebook-f"></i
                            ></a>
                            <a href="#" target="_blank"
                                ><i class="fab fa-twitter"></i
                            ></a>
                            <a href="#" target="_blank"
                                ><i class="fab fa-instagram"></i
                            ></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>Lucy Eva</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team3.jpg" alt="team" />
                        <div class="social">
                            <a href="#" target="_blank"
                                ><i class="fab fa-facebook-f"></i
                            ></a>
                            <a href="#" target="_blank"
                                ><i class="fab fa-twitter"></i
                            ></a>
                            <a href="#" target="_blank"
                                ><i class="fab fa-instagram"></i
                            ></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>Steven Smith</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team4.jpg" alt="team" />
                        <div class="social">
                            <a href="#" target="_blank"
                                ><i class="fab fa-facebook-f"></i
                            ></a>
                            <a href="#" target="_blank"
                                ><i class="fab fa-twitter"></i
                            ></a>
                            <a href="#" target="_blank"
                                ><i class="fab fa-instagram"></i
                            ></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>Sarah Taylor</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Team Area -->

<app-free-trial-area></app-free-trial-area>
