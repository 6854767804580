<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img
                                class="footer-logo"
                                src="assets/img/white-logo.png"
                                alt="image"
                            />
                        </a>
                        <p>
                            Single Insight is Neuclon Corporation's manufacturing-focused 
                            subsidiary headquartered in Connecticut, USA. We're committed to 
                            helping manufacturers improve their efficiency, profitability, 
                            and sustainability by getting more out of their data.
                        </p>
                    </div>
                    <h3>Get in touch</h3>
                    <ul class="social">
                        <li>
                            <a routerLink="/contact">
                                <!-- <i class="flaticon-facebook-letter-logo"></i> -->
                                <img
                                    class="height-20"
                                    src="../../../../assets/img/email.png"
                                    alt="image not found"
                                />
                            </a>
                        </li>
                        <li>
                            <a routerLink="/contact">
                                <!-- <i class="flaticon-twitter"></i
                            > -->
                                <img
                                    class="height-20"
                                    src="../../../../assets/img/phone-call.png"
                                    alt="image not found"
                                />
                            </a>
                        </li>
                        <li>
                            <a routerLink="/contact">
                                <!-- <i class="flaticon-instagram-logo"></i
                            > -->
                                <img
                                    class="height-20"
                                    src="../../../../assets/img/link.png"
                                    alt="image not found"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="single-footer-widget">
                    <h3>Services</h3>

                    <ul class="footer-services-list">
                        <li>
                            <a routerLink="/"
                                >Digital Transformation Strategy</a
                            >
                        </li>
                        <li><a routerLink="/">Integrations</a></li>
                        <li><a routerLink="/">Cloud</a></li>
                        <li><a routerLink="/">Data & Analytics</a></li>
                        <li><a routerLink="/">Support</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="single-footer-widget d-company margin-left5">
                    <h3>Company</h3>

                    <ul class="footer-contact-list">
                        <li><a routerLink="/">About Single Insight</a></li>
                        <li><a routerLink="/">Careers</a></li>
                        <li><a routerLink="/">Investor Relations</a></li>
                        <li><a routerLink="/">Success Stories</a></li>
                        <li><a routerLink="/">Industry Reports</a></li>
                        <li><a routerLink="/">Partners</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="single-footer-widget">
                    <h3>Location</h3>

                    <ul class="quick-links-list">
                        <li>
                            Connecticut Center for Advanced Technology <br>
                            222 Pitkin Street<br>
                            East Hartford, Connecticut<br>
                            USA, 06108

                        </li>
                        <li>
                            <a href="tel:+18559638256">+1 855 963 8256</a>
                            <br>
                            <a href="mailto:info@singleinsight.io">info@singleinsight.io</a>
                        </li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <p>Copyright {{copyrightYear}} © Single Insight</p>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <ul>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="circle-map">
        <img src="assets/img/circle-map.png" alt="image" />
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
<!-- End Footer Area -->

<div class="go-top">
    <i class="fas fa-arrow-up"></i><i class="fas fa-arrow-up"></i>
</div>
