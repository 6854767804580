<section class="services-area bg-f2f6f9 ptb-110 onlyBlueBackground d-usecase-main-container">

    <h2>Apply the advantages of Single Insight across your entire business</h2>

    <div class="container">
        <ul
            class="nav nav-pills mb-3 d-flex justify-content-between"
            id="pills-tab"
            role="tablist"
        >
            <li class="nav-item pointer" role="presentation">
                <span
                    class="nav-link borderWhite onlyWhiteColor fontDosis fontSize20 active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                >
                    Manufacturing
                </span>
            </li>
            <li class="nav-item pointer" role="presentation">
                <span
                    class="nav-link borderWhite onlyWhiteColor fontDosis fontSize20"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                >
                    Supply Chain
                </span>
            </li>
            <li class="nav-item pointer" role="presentation">
                <span
                    class="nav-link borderWhite onlyWhiteColor fontDosis fontSize20"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                >
                    Planning
                </span>
            </li>
            <li class="nav-item pointer" role="presentation">
                <span
                    class="nav-link borderWhite onlyWhiteColor fontDosis fontSize20"
                    id="pills-marketing-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-marketing"
                    role="tab"
                    aria-controls="pills-marketing"
                    aria-selected="false"
                >
                    Quality
                </span>
            </li>
            <li class="nav-item pointer" role="presentation">
                <span
                    class="nav-link borderWhite onlyWhiteColor fontDosis fontSize20"
                    id="pills-product-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-product"
                    role="tab"
                    aria-controls="pills-product"
                    aria-selected="false"
                >
                    Product Management
                </span>
            </li>
            <li class="nav-item pointer" role="presentation">
                <span
                    class="nav-link borderWhite onlyWhiteColor fontDosis fontSize20"
                    id="pills-design-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-design"
                    role="tab"
                    aria-controls="pills-design"
                    aria-selected="false"
                >
                    Operations Excellence
                </span>
            </li>
            <li class="nav-item pointer" role="presentation">
                <span
                    class="nav-link borderWhite onlyWhiteColor fontDosis fontSize20"
                    id="pills-finance-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-finance"
                    role="tab"
                    aria-controls="pills-finance"
                    aria-selected="false"
                >
                    Finance
                </span>
            </li>
            <li class="nav-item pointer" role="presentation">
                <span
                    class="nav-link borderWhite onlyWhiteColor fontDosis fontSize20"
                    id="pills-hr-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-hr"
                    role="tab"
                    aria-controls="pills-hr"
                    aria-selected="false"
                >
                    Maintenance
                </span>
            </li>
            <li class="nav-item pointer" role="presentation">
                <span
                    class="nav-link borderWhite onlyWhiteColor fontDosis fontSize20"
                    id="pills-hr-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-csuite"
                    role="tab"
                    aria-controls="pills-csuite"
                    aria-selected="false"
                >
                    C-suite
                </span>
            </li>
        </ul>
        <div class="tab-content onlyWhiteColor" id="pills-tabContent">
            <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabindex="0"
            >
                <div class="container row">
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <ul>
                            <!-- <li class="custom-icon-li"> -->
                            <li>
                                <div class="mt-4">
                                    <!-- <span class="tabs-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-eye-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"
                                    />
                                    <path
                                        d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                                    />
                                </svg>
                            </span> -->
                                    <h4 class="onlyWhiteColor fontBold">
                                        Real-time Performance Monitoring
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Monitor key performance
                                        indicators (KPIs) in real-time, enabling
                                        proactive decision-making and
                                        performance optimization.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Production Efficiency Enhancement
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Identify inefficiencies, bottlenecks, and process
                                        improvements, leading to increased
                                        productivity and reduced costs.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Workforce Management
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Optimize workforce allocation, track labor
                                        productivity, and identify training
                                        needs for better resource utilization.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Predictive Maintenance
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Proactively identify equipment failures and schedule
                                        maintenance activities, minimizing
                                        downtime and optimizing production
                                        schedules.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Resource Allocation and Planning
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Optimize resource allocation, streamline
                                        production schedules, and ensure optimal
                                        utilization of people and equipment.
                                    </p>
                                </div>
                            </li>
                        </ul>

                        <div class="btn-box btn-box-getStarted mt-4">
                            <a routerLink="/contact" class="btn btn-getStarted"
                                >Get Started</a
                            >
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 full-center">
                        <!-- <div class="webinar-video-image">
                            <a
                                href="../../../../assets/img/overview.mp4"
                                class="video-btn popup-youtube"
                                ><i class="flaticon-play-button"></i
                            ></a>
                        </div> -->
                        <div>
                            <img
                                class=""
                                src="../../../assets/videos/05.DigitalTwin.gif"
                                alt="digital twin"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
                tabindex="0"
            >
                <div class="container row">
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <ul>
                            <!-- <li class="custom-icon-li"> -->
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Enhanced Visibility
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Gain deep
                                        visibility into the entire supply chain,
                                        enabling proactive decision-making and
                                        risk management.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Supplier Performance Evaluation
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Evaluate
                                        supplier performance based on key
                                        metrics, such as delivery times,
                                        quality, and cost, enabling informed
                                        supplier selection and optimization.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Demand Forecasting and Inventory
                                        Management
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Forecast
                                        demand patterns, optimize inventory
                                        levels, and ensure timely availability
                                        of materials, reducing stockouts and
                                        minimizing carrying costs.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Cost Optimization
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Identify
                                        cost-saving opportunities, negotiate
                                        better contracts, and optimize sourcing
                                        strategies, resulting in improved
                                        financial performance and operational
                                        efficiency.
                                    </p>
                                </div>
                            </li>
                        </ul>

                        <div class="btn-box btn-box-getStarted mt-4">
                            <a routerLink="/contact" class="btn btn-getStarted"
                                >Get Started</a
                            >
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 full-center">
                        <div class="webinar-video-image">
                            <a
                                href="../../../../assets/img/overview.mp4"
                                class="video-btn popup-youtube"
                                ><i class="flaticon-play-button"></i
                            ></a>
                        </div>
                        <div>
                            <img
                                class="mt-4"
                                src="../../../assets/img/dynamic-tab.png"
                                alt="image not found"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
                tabindex="0"
            >
                <div class="container row">
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <ul>
                            <!-- <li class="custom-icon-li"> -->
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Optimize Production Planning
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Make data-driven decisions and optimize
                                        production planning processes.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Demand Forecasting
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Accurately
                                        forecast demand, enabling proactive
                                        planning and inventory management.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Real-time Monitoring
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Monitor production metrics in real-time,
                                        allowing for immediate adjustments to
                                        production schedules and resource
                                        allocation.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Performance Analysis
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Analyze production data to identify
                                        bottlenecks, inefficiencies, and areas
                                        for improvement, enhancing overall
                                        production performance and output.
                                    </p>
                                </div>
                            </li>
                        </ul>

                        <div class="btn-box btn-box-getStarted mt-4">
                            <a routerLink="/contact" class="btn btn-getStarted"
                                >Get Started</a
                            >
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 full-center">
                        <div class="webinar-video-image">
                            <a
                                href="../../../../assets/img/overview.mp4"
                                class="video-btn popup-youtube"
                                ><i class="flaticon-play-button"></i
                            ></a>
                        </div>
                        <div>
                            <img
                                class="mt-4"
                                src="../../../assets/img/dynamic-tab.png"
                                alt="image not found"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="pills-marketing"
                role="tabpanel"
                aria-labelledby="pills-marketing-tab"
                tabindex="0"
            >
                <div class="container row">
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <ul>
                            <!-- <li class="custom-icon-li"> -->
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Data-Driven Quality Control
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Utilize quality management data
                                        analytics to implement data-driven
                                        quality control processes, ensuring
                                        consistent product quality and
                                        compliance.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Root Cause Analysis
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Identify root
                                        causes of defects or deviations,
                                        enabling targeted corrective actions and
                                        continuous improvement.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Real-time Quality Monitoring
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Monitor quality metrics in real-time to
                                        detect quality issues early on, allowing
                                        for immediate interventions and
                                        preventing defects from reaching
                                        customers.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Supplier Quality Management
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Evaluate and monitor supplier quality
                                        performance, enabling effective supplier
                                        selection and collaboration for improved
                                        overall product quality.
                                    </p>
                                </div>
                            </li>
                        </ul>

                        <div class="btn-box btn-box-getStarted mt-4">
                            <a routerLink="/contact" class="btn btn-getStarted"
                                >Get Started</a
                            >
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 full-center">
                        <div class="webinar-video-image">
                            <a
                                href="../../../../assets/img/overview.mp4"
                                class="video-btn popup-youtube"
                                ><i class="flaticon-play-button"></i
                            ></a>
                        </div>
                        <div>
                            <img
                                class="mt-4"
                                src="../../../assets/img/dynamic-tab.png"
                                alt="image not found"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="pills-product"
                role="tabpanel"
                aria-labelledby="pills-product-tab"
                tabindex="0"
            >
                <div class="container row">
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <ul>
                            <!-- <li class="custom-icon-li"> -->
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Product Line Simplification
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Analyze product performance to streamline the product portfolio, focusing on high
                                        performing items and reducing complexity and costs.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Pricing Optimization
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Use data analytics for dynamic pricing based on market trends and customer demand, 
                                        maximizing sales and profitability.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Margin Improvement
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Identify cost reduction opportunities and focus on high-margin products to enhance 
                                        overall profitability.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Part Number Reduction
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Rationalize part numbers through standardization and consolidation, simplifying 
                                        inventory management and reducing production complexity.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Resource Allocation
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Allocate resources efficiently to high-priority projects and product lines, optimizing 
                                        utilization for innovation and growth.
                                        
                                    </p>
                                </div>
                            </li>
                        </ul>

                        <div class="btn-box btn-box-getStarted mt-4">
                            <a routerLink="/contact" class="btn btn-getStarted"
                                >Get Started</a
                            >
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 full-center">
                        <div class="webinar-video-image">
                            <a
                                href="../../../../assets/img/overview.mp4"
                                class="video-btn popup-youtube"
                                ><i class="flaticon-play-button"></i
                            ></a>
                        </div>
                        <div>
                            <img
                                class="mt-4"
                                src="../../../assets/img/dynamic-tab.png"
                                alt="image not found"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="pills-design"
                role="tabpanel"
                aria-labelledby="pills-design-tab"
                tabindex="0"
            >
                <div class="container row">
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <ul>
                            <!-- <li class="custom-icon-li"> -->
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Data-Driven Process Optimization
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Identify process inefficiencies, eliminate waste, 
                                        streamline and standardize operations.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Lean and Continuous Improvement
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Identify
                                        improvement opportunities, monitor key
                                        performance indicators (KPIs), and drive
                                        continuous improvement initiatives for
                                        sustained operational excellence.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Root Cause Analysis
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Perform root
                                        cause analysis, identifying underlying
                                        issues and implementing targeted
                                        solutions to enhance operational
                                        efficiency and eliminate recurring
                                        problems.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Performance Metrics and Dashboards
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Track and
                                        visualize performance metrics through
                                        interactive dashboards, enabling
                                        real-time monitoring and informed
                                        decision-making for lean and operations
                                        excellence.
                                    </p>
                                </div>
                            </li>
                        </ul>

                        <div class="btn-box btn-box-getStarted mt-4">
                            <a routerLink="/contact" class="btn btn-getStarted"
                                >Get Started</a
                            >
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 full-center">
                        <div class="webinar-video-image">
                            <a
                                href="../../../../assets/img/overview.mp4"
                                class="video-btn popup-youtube"
                                ><i class="flaticon-play-button"></i
                            ></a>
                        </div>
                        <div>
                            <img
                                class="mt-4"
                                src="../../../assets/img/dynamic-tab.png"
                                alt="image not found"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="pills-finance"
                role="tabpanel"
                aria-labelledby="pills-finance-tab"
                tabindex="0"
            >
                <div class="container row">
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <ul>
                            <!-- <li class="custom-icon-li"> -->
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Seamless Data Integration
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Integrate ERP (Enterprise Resource
                                        Planning) and CRM (Customer Relationship
                                        Management) systems with MES
                                        (Manufacturing Execution System) to
                                        enable seamless data flow and real-time
                                        visibility across finance, production,
                                        and customer management.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Improved Financial Analysis
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Perform comprehensive
                                        financial analysis, gaining insights
                                        into costs, revenue, and profitability
                                        associated with manufacturing
                                        operations.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Streamlined Order-to-Cash Process
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Streamline the order-to-cash process,
                                        improving efficiency, accuracy, and
                                        customer satisfaction while reducing
                                        manual effort and errors.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Cost Control and Forecasting
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Optimize cost control and
                                        forecasting, enabling better financial
                                        planning, budgeting, and
                                        decision-making.
                                    </p>
                                </div>
                            </li>
                        </ul>

                        <div class="btn-box btn-box-getStarted mt-4">
                            <a routerLink="/contact" class="btn btn-getStarted"
                                >Get Started</a
                            >
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 full-center">
                        <div class="webinar-video-image">
                            <a
                                href="../../../../assets/img/overview.mp4"
                                class="video-btn popup-youtube"
                                ><i class="flaticon-play-button"></i
                            ></a>
                        </div>
                        <div>
                            <img
                                class="mt-4"
                                src="../../../assets/img/dynamic-tab.png"
                                alt="image not found"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="pills-hr"
                role="tabpanel"
                aria-labelledby="pills-hr-tab"
                tabindex="0"
            >
                <div class="container row">
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <ul>
                            <!-- <li class="custom-icon-li"> -->
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Predictive Maintenance
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Predict
                                        equipment failures and schedule
                                        maintenance activities proactively,
                                        minimizing unplanned downtime and
                                        maximizing asset utilization.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Condition Monitoring
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Monitor real-time equipment sensor data
                                        to detect anomalies, identify potential
                                        issues, and schedule maintenance
                                        activities based on actual equipment
                                        conditions.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Asset Performance Analysis
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Assess asset
                                        performance, identify maintenance
                                        trends, and optimize maintenance
                                        strategies for improved reliability and
                                        cost efficiency.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Spare Parts Optimization
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Optimize spare parts inventory, ensuring
                                        the availability of critical components
                                        while minimizing inventory carrying
                                        costs.
                                    </p>
                                </div>
                            </li>
                        </ul>

                        <div class="btn-box btn-box-getStarted mt-4">
                            <a routerLink="/contact" class="btn btn-getStarted"
                                >Get Started</a
                            >
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 full-center">
                        <div class="webinar-video-image">
                            <a
                                href="../../../../assets/img/overview.mp4"
                                class="video-btn popup-youtube"
                                ><i class="flaticon-play-button"></i
                            ></a>
                        </div>
                        <div>
                            <img
                                class="mt-4"
                                src="../../../assets/img/dynamic-tab.png"
                                alt="image not found"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="pills-csuite"
                role="tabpanel"
                aria-labelledby="pills-hr-tab"
                tabindex="0"
            >
                <div class="container row">
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <ul>
                            <!-- <li class="custom-icon-li"> -->
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Strategic Decision-Making 
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Empowers informed, strategic decisions with insights into market trends, customer 
                                        behavior, and operational metrics.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Financial Performance
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Enhances revenue, reduces costs, and improves margins through deep financial data 
                                        analysis, boosting shareholder value
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Operational Excellence
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Identifies inefficiencies and bottlenecks, enabling lean operational improvements for 
                                        better productivity and customer satisfaction.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Risk Management
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Leverages data to proactively identify and mitigate risks, protecting company assets 
                                        and reputation.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="mt-4">
                                    <h4 class="onlyWhiteColor fontBold">
                                        Innovation and Growth
                                    </h4>

                                    <p class="mt-2 text-white fontSize15">
                                        Drives market innovation and growth by uncovering trends and product development 
                                        opportunities through analytics
                                    </p>
                                </div>
                            </li>
                        </ul>

                        <div class="btn-box btn-box-getStarted mt-4">
                            <a routerLink="/contact" class="btn btn-getStarted"
                                >Get Started</a
                            >
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 full-center">
                        <div class="webinar-video-image">
                            <a
                                href="../../../../assets/img/overview.mp4"
                                class="video-btn popup-youtube"
                                ><i class="flaticon-play-button"></i
                            ></a>
                        </div>
                        <div>
                            <img
                                class="mt-4"
                                src="../../../assets/img/dynamic-tab.png"
                                alt="image not found"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="shape-img1">
        <img src="assets/img/shape/shape1.png" alt="image" />
    </div> -->
    <div class="shape-img3">
        <img src="assets/img/shape/shape3.png" alt="image" />
    </div>
    <div class="shape-img2">
        <img src="assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="assets/img/shape/shape5.svg" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="assets/img/shape/shape4.svg" alt="image" />
    </div>
    <div class="dot-shape1">
        <img src="assets/img/shape/dot1.png" alt="image" />
    </div>
    <div class="dot-shape2">
        <img src="assets/img/shape/dot3.png" alt="image" />
    </div>
</section>
