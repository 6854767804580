<section class="services-section bg-f2f6f9 ptb-110">
    <div class="container">
        <!-- <div class="section-title">
            <h2>We Offer Professional Solutions</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div> -->

        <div class="tab services-tab-list">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <ul class="tabs">
                        <li>
                            <a href="#">
                                <img
                                    class="features-icon"
                                    src="../../../assets/img/Features/connect.png"
                                    alt="image"
                                />
                                Connect
                            </a>
                        </li>

                        <li>
                            <a href="#">
                                <img
                                    class="features-icon"
                                    src="../../../assets/img/Features/model.png"
                                    alt="image"
                                />
                                Model
                            </a>
                        </li>

                      

                        <li>
                            <a href="#">
                                <img
                                    class="features-icon"
                                    src="../../../assets/img/Features/analyze.png"
                                    alt="image"
                                />
                                Analyze
                            </a>
                        </li>

                        <li>
                            <a href="#">
                                <img
                                    class="features-icon"
                                    src="../../../assets/img/Features/intelligence.png"
                                    alt="image"
                                />
                                Intelligence
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="tab-content">
                        <div class="tabs-item">
                            <div class="image">
                                <iframe 
                                    class="d-features-videos"
                                    width="560" 
                                    height="315" 
                                    src="https://www.youtube.com/embed/MI3TD2SgaoI?si=H1VGt_p1DcpZpvKq&amp;start=40" 
                                    title="YouTube video player" 
                                    frameborder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                    allowfullscreen
                                >
                                </iframe>
                                <!-- <img class="services-img"
                                    src="../../../assets/img/Features/connect.jpg"
                                    alt="image"
                                /> -->
                            </div>
                            <div class="content">
                                <p>
                                    Integrate all your assets and data sources
                                    effortlessly within minutes while ensuring
                                    automatic data cleanup. Maximize efficiency
                                    by leveraging our prebuilt solutions and
                                    workflows tailored for common use cases.
                                </p>
                                <a routerLink="/" class="btn btn-primary"
                                    >Read More</a
                                >
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="image">
                                <!-- <img
                                class="services-img"
                                src="../../../assets/img/Features/analyze.jpg"
                                    alt="image"
                                /> -->
                                <iframe class="d-features-videos" width="560" height="315" src="https://www.youtube.com/embed/rp4RWqgAGY4?si=RPo3-BJYb8K0vioZ&amp;start=3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div class="content">
                                <p>
                                    Unleash the power of data modeling. Rapidly
                                    create scalable predictive AI models, boost
                                    collaboration in training and deploying
                                    machine learning, and leverage automated
                                    data cleanup and prebuilt solutions.
                                </p>
                                <a routerLink="/" class="btn btn-primary"
                                    >Read More</a
                                >
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="image">
                                <!-- <img
                                class="services-img"
                                src="../../../assets/img/Features/feature.jpg"
                                    alt="image"
                                /> -->
                                <iframe class="d-features-videos" width="560" height="315" src="https://www.youtube.com/embed/ba12i_A41Rw?si=4wUrt-N83cE7aAQZ&amp;start=2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div class="content">
                                <p>
                                    Experience the transformative power of our
                                    Manufacturing Execution System (MES). With
                                    an intuitive GUI, drag-and-drop
                                    functionality, and a no-code approach, you
                                    can easily build your own customized
                                    workflows for seamless production
                                    operations.
                                </p>
                                <a routerLink="/" class="btn btn-primary"
                                    >Read More</a
                                >
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="image">
                                <!-- <img
                                class="services-img"
                                src="../../../assets/img/Features/execute.jpg"
                                    alt="image"
                                /> -->
                                <iframe class="d-features-videos" width="560" height="315" src="https://www.youtube.com/embed/OV6sMRsgLHA?si=iYyydiecR0Owrp38&amp;start=3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div class="content">
                                <p>
                                    Unlock the full potential of your data with
                                    our data analysis capabilities. Our
                                    intuitive dashboard provides actionable
                                    insights, while prebuilt models and alerts
                                    recommend optimizations for informed
                                    decision-making.
                                </p>
                                <a routerLink="/" class="btn btn-primary"
                                    >Read More</a
                                >
                            </div>
                        </div>

                        <!-- <div class="tabs-item">
                            <div class="image">
                                <img
                                class="services-img"
                                src="../../../assets/img/Features/intelligence.jpg"
                                    alt="image"
                                />
                            </div>
                            <div class="content">
                                <p>
                                    Elevate your manufacturing business
                                    intelligence with our powerful platform. Act
                                    as a copilot as we recommend insights,
                                    analyze relationships, and provide
                                    actionable recommendations for optimal
                                    performance.
                                </p>
                                <a routerLink="/" class="btn btn-primary"
                                    >Read More</a
                                >
                            </div>
                        </div>

                        <div class="tabs-item">
                            <div class="image">
                                <img
                                class="services-img"
                                src="../../../assets/img/Features/model.jpg"
                                    alt="image"
                                />
                            </div>
                            <div class="content">
                                <p>
                                    There are many variations of passages of
                                    Lorem Ipsum available, but the majority have
                                    suffered alteration in some form, by
                                    injected humour, or randomized words which
                                    don't look even slightly believable. If you
                                    are going to use a passage.
                                </p>
                                <a routerLink="/" class="btn btn-primary"
                                    >Read More</a
                                >
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img1">
        <img src="assets/img/shape/shape1.png" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="assets/img/shape/shape3.png" alt="image" />
    </div>
    <div class="shape-img2">
        <img src="assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="assets/img/shape/shape5.svg" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="assets/img/shape/shape4.svg" alt="image" />
    </div>
    <div class="dot-shape1">
        <img src="assets/img/shape/dot1.png" alt="image" />
    </div>
    <div class="dot-shape2">
        <img src="assets/img/shape/dot3.png" alt="image" />
    </div>
</section>
