import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { AlertService } from "@full-fledged/alerts";
import { ApiService } from "src/app/Service/api.service";

@Component({
    selector: "app-free-trial-area",
    templateUrl: "./free-trial-area.component.html",
    styleUrls: ["./free-trial-area.component.scss"],
})
export class FreeTrialAreaComponent implements OnInit {
    subscriptionForm: FormGroup;

    email: FormControl = new FormControl("", [
        Validators.required,
        Validators.email,
    ]);

    constructor(
        private toast: AlertService,
        private fb: FormBuilder,
        private api: ApiService
    ) {}

    ngOnInit() {
        this.subscriptionForm = this.fb.group({
            email: this.email,
        });
    }

    submit() {
        const payload = {
            email: this.subscriptionForm.value.email,
        };

        console.log("Free Trial");

        this.api.mailchimpSeeDemoAudience(payload).subscribe((res) => {
            console.log(res);
        });

        this.api.subscriptionApi(payload).subscribe(
            (res: any) => {
                if (res.success) {
                    this.subscriptionForm.reset();
                    this.toast.success(res.msg);
                } else {
                    this.subscriptionForm.reset();
                    this.toast.danger(res.msg);
                }
            },
            (err) => this.api.catchBlock(err)
        );
    }
}
