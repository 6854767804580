import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AlertService } from "@full-fledged/alerts";

@Injectable({
    providedIn: "root",
})
export class ApiService {
    constructor(private http: HttpClient, private toast: AlertService) {}

    subscriptionApi(body: any) {
        return this.http.post(`${environment.baseUrl}/si/subscriptions`, body);
    }

    contactApi(body: any) {
        return this.http.post(`${environment.baseUrl}/si/contacts`, body);
    }

    mailchimpContactUsAudience(body: any) {
        return this.http.post(
            `${environment.apiUrl}/mailchimp/contact-us`,
            body
        );
    }

    mailchimpSeeDemoAudience(body: { email: string }) {
        return this.http.post(
            `${environment.apiUrl}/mailchimp/free-trial`,
            body
        );
    }

    catchBlock(error: any) {
        this.toast.danger(
            error?.msg ||
                error?.error?.msg ||
                error?.message ||
                error?.error?.message ||
                "something went wrong"
        );
    }
}
