import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ApiService } from "./api.service";

@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {
    constructor(
        private route: Router,
        private ngxService: NgxUiLoaderService,
        private api: ApiService
    ) {}

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.ngxService.start();

        return new Observable((observer) => {
            next.handle(req).subscribe(
                (res: HttpResponse<any>) => {
                    this.ngxService.stop();
                    if (res instanceof HttpResponse) {
                        observer.next(res);
                    }
                },
                (err: HttpErrorResponse) => {
                    this.api.catchBlock(err);
                    this.ngxService.stop();
                }
            );
        });
    }
}
