<section class="blog-area ptb-110">
    <div class="container">
        <div class="section-title">
            <h2 class="onlyBlueColor">Resources</h2>
            <!-- <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p> -->
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details/1"
                            ><img
                                class="heigh-20 w-26"
                                src="../../../assets/img/blog/blog1/thumbnail.jpeg"
                                alt="image"
                        /></a>
                    </div>
                    <div class="entry-post-content bg-f2f6f9">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>May 15, 2023</li>
                            </ul>
                        </div>
                        <h3>
                            <a routerLink="/blog-details/1"
                                >The Power of Single Source of Truth in
                                Manufacturing</a
                            >
                        </h3>
                        <p>
                            In today's data-driven world, the manufacturing
                            industry faces unprecedented challenges...
                        </p>
                        <a routerLink="/blog-details/1" class="learn-more-btn"
                            >Read Story <i class="flaticon-add"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details/2"
                            ><img
                                class="heigh-20"
                                src="../../../assets/img/blog/blog2/shutterstock_1562030050-min.jpg"
                                alt="image"
                        /></a>
                    </div>
                    <div class="entry-post-content bg-f2f6f9">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>May 23, 2023</li>
                            </ul>
                        </div>
                        <h3>
                            <a routerLink="/blog-details/2"
                                >Embracing Data and Analytics: Shaping the
                                Future of Manufacturing</a
                            >
                        </h3>
                        <p>
                            In the dynamic world of advanced manufacturing,
                            data, and analytics have become...
                        </p>
                        <a routerLink="/blog-details/2" class="learn-more-btn"
                            >Read Story <i class="flaticon-add"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details/3"
                            ><img
                                class="heigh-20"
                                src="../../../assets/img/blog/blog3/shutterstock_2300710375-min.jpg"
                                alt="image"
                        /></a>
                    </div>
                    <div class="entry-post-content bg-f2f6f9">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>June 5, 2023</li>
                            </ul>
                        </div>
                        <h3>
                            <a routerLink="/blog-details/3"
                                >Unleashing the Power of Change Management in
                                Industrial Digital Transformation
                            </a>
                        </h3>
                        <p>
                            In today's rapidly evolving industrial landscape...
                        </p>
                        <a routerLink="/blog-details/3" class="learn-more-btn"
                            >Read Story <i class="flaticon-add"></i
                        ></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2">
        <img src="assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="assets/img/shape/shape3.png" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="assets/img/shape/shape4.svg" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="assets/img/shape/shape5.svg" alt="image" />
    </div>
    <div class="shape-img7">
        <img src="assets/img/shape/shape3.png" alt="image" />
    </div>
    <div class="dot-shape1">
        <img src="assets/img/shape/dot1.png" alt="image" />
    </div>
    <div class="dot-shape2">
        <img src="assets/img/shape/dot3.png" alt="image" />
    </div>
    <div class="dot-shape4">
        <img src="assets/img/shape/dot4.png" alt="image" />
    </div>
    <div class="dot-shape5">
        <img src="assets/img/shape/dot5.png" alt="image" />
    </div>
    <div class="dot-shape6">
        <img src="assets/img/shape/dot6.png" alt="image" />
    </div>
</section>
