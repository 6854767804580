import { Component } from '@angular/core';

@Component({
  selector: 'app-generative-ai',
  templateUrl: './generative-ai.component.html',
  styleUrls: ['./generative-ai.component.scss']
})
export class GenerativeAiComponent {
  currentIndex: null|number = null;
  currentImage: string = 'assets/img/sample/ai-app-img-1.jpg';

  sections = [
    {
      index:0,
      name: 'Generative AI',
      content: 'Save time and effort by using plain-language requests to ask questions, get information, and generate reports – with no programming skills needed.',
      link:'someurllink',
      mediaLink:"assets/img/sample/ai-app-img-1.jpg",
    },
    {
      index:1,
      name: ' Non-obvious insight',
      content: 'Find trends, insights, and opportunities that standard methodologies can’t and are hard for people to spot.',
      link:'someurllink',
      mediaLink:"assets/img/sample/ai-app-img-2.jpg",
    },
    {
      index:2,
      name: ' Process Automation',
      content: 'Save time and money by automating repetitive tasks such as data extraction, manipulation and report generation',
      link:'someurllink',
      mediaLink:"assets/img/sample/ai-app-img-3.jpg",
    },
    {
      index:3,
      name: 'AI Unique to your Enterprise',
      content: 'We train your AI on your company’s data, making it unique.  In addition to be exclusive to you, the results it provides will be relevant to your own data (not generic results from the internet).',
      link:'someurllink',
      mediaLink:"assets/img/sample/ai-app-img-4.jpg",
    },
    {
      index:4,
      name: 'Always get answers smarter',
      content: ':  Your AI will get smarter over time as it sees more data.  This means you and your team will get smarter, too.',
      link:'someurllink',
      mediaLink:"assets/img/sample/ai-app-img-5.jpg",
    },
    {
      index:5,
      name: 'No-code Machine Learning Platform',
      content: 'Deploy pre-trained machine learning algorithm with no-code - making it easier for users at all levels to gain deep insights from data.',
      link:'someurllink',
      mediaLink:"assets/img/sample/ai-app-img-1.jpg",
    },
  ];

  expand(index: any) {
    this.currentImage = this.sections[index].mediaLink;
    if(this.currentIndex === index) {
      this.currentIndex = null;
      return;
    }
    this.currentIndex = index;
  }

}