<section class="services-section bg-f2f6f9 ptb-110">
    <div class="container">
        <h1 class="mb-4">
            <span class="onlyBlueColor fw-bold">Platforms</span>
        </h1>
        <h3 class="mb-4 mt-2">
            <span class="onlyBlueColor"
                >Connect people,functions, and systems to drive innovation,
                increase business agility and unlock productivity</span
            >
        </h3>

        <div class="container">
            <div class="row fontDosis">
                <div class="col-lg-4 col-md-12 col-sm-12 aiApplicationColDiv">
                    <div class="mt-2">
                        <span class="fontSize1r onlyBlackColor">Connect</span>

                        <br />
                        <span class="fontSize20 onlyBlueColor">
                            No-Code Integration Platform; with drivers and
                            connectors to industry systems
                            <ul>
                                <li>
                                    Connect Apps and services to pre-built
                                    connectors
                                </li>
                                <li>
                                    Access data and systems in a cloud
                                    environment
                                </li>
                                <li>Drag-and-drop interface</li>
                            </ul>
                        </span>
                    </div>
                    <div class="mt-2">
                        <span class="fontSize1r onlyBlackColor">Automate</span>

                        <br />
                        <span class="fontSize20 onlyBlueColor">
                            AI models and automation tools engineered to drive
                            manufacturing efficiency
                            <ul>
                                <li>Recommendation Engine</li>
                                <li>Vision Inspection System</li>
                                <li>Document Extraction System</li>
                                <li>Enterprise Chatbot using GPT</li>
                                <li>Quality Management</li>
                            </ul>
                        </span>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <img
                        src="../../../assets/img/Group 24.png"
                        alt="image not found"
                    />
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 aiApplicationColDiv">
                    <div class="mt-2">
                        <span class="fontSize1r onlyBlackColor">Visualize</span>

                        <br />
                        <span class="fontSize20 onlyBlueColor">
                            Fully customizable dashboard, preconfigured with
                            over 100 Manufacturing KPI widgets
                            <ul>
                                <li>Templates</li>
                                <li>Drag-and-Drop</li>
                                <li>Customizable Widgets</li>
                                <li>Dynamic</li>
                                <li>Collaborative</li>
                            </ul>
                        </span>
                    </div>
                    <div class="mt-2">
                        <span class="fontSize1r onlyBlackColor"
                            >CoPilot</span
                        >

                        <br />
                        <span class="fontSize20 onlyBlueColor">
                            AI Assistant driven by Manufacturing Big Data to
                            provide live insight and recommendations
                            <ul>
                                <li>Recommendation Algorithm</li>
                                <li>Live Insight From Data</li>
                                <li>Publish to Microsoft 365 Apps</li>
                                <li>Prescriptive Responses</li>
                            </ul>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img1">
        <img src="assets/img/shape/shape1.png" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="assets/img/shape/shape3.png" alt="image" />
    </div>
    <div class="shape-img2">
        <img src="assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="assets/img/shape/shape5.svg" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="assets/img/shape/shape4.svg" alt="image" />
    </div>
    <div class="dot-shape1">
        <img src="assets/img/shape/dot1.png" alt="image" />
    </div>
    <div class="dot-shape2">
        <img src="assets/img/shape/dot3.png" alt="image" />
    </div>
</section>
