import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import logoData from "src/assets/data/logo-data";


@Component({
    selector: 'app-integrations',
    templateUrl:'./integrations.component.html',
    styleUrls:['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit{
    processedData: any[] = [];
    customOptions: OwlOptions = {
        autoplay:true,
        autoplayTimeout:200,
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        autoplayHoverPause:false,
        dots: false,
        nav: false
      };

    ngOnInit(): void {
        this.processedData = this.processData(logoData);
    }

    processData(data:any[]){
        const pData = [];
        data.forEach((item,index)=>{
            item['categoryType'].forEach((category:any)=>pData.push({
                id: pData.length+1,
                image: category.logo,
                thumbImage: category.logo,
                alt: category.categoryName,
                // title: category.categoryName
            }))
        })
        return pData;
    }
}