<section class="blog-area ptb-110">
    <div class="container">
        <h2 class="onlyGreenColor">Get started with Single Insight</h2>
        <p>
            Single Insight is at the forefront of the Manufacturing Data
            Analytics industry. Transforming data into meaningful insights and
            driving success for manufacturing businesses worldwide.
        </p>
        <p>
            We enable manufacturers to accelerate their digital transformation
            journey by providing the fastest, most predictable, and most
            profitable route to digitalization with our cloud-based software
            solution.
        </p>
        <p>
            Our tailored analytics solutions blend cutting-edge technology with
            deep industry know-how to transform raw data into actionable
            insights that drive operational excellence.
        </p>
        <p>
            At Single Insight, we don't just analyze data-we give it purpose.
            Discover how our innovative solutions can shape your manufacturing
            future.
        </p>
        <p>
            Explore our services, connect with our team, and start your journey
            towards a smarter, more productive tomorrow with Single Insight.
        </p>
        <p class="d-flex">
            <span> Visit us at </span> &nbsp;&nbsp;
            <a class="a-tag" href="www.singleinsight.io"
                >www.singleinsight.io</a
            >
        </p>
    </div>

    <div class="shape-img2">
        <img src="assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="assets/img/shape/shape3.png" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="assets/img/shape/shape4.svg" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="assets/img/shape/shape5.svg" alt="image" />
    </div>
    <div class="shape-img7">
        <img src="assets/img/shape/shape3.png" alt="image" />
    </div>
    <div class="dot-shape1">
        <img src="assets/img/shape/dot1.png" alt="image" />
    </div>
    <div class="dot-shape2">
        <img src="assets/img/shape/dot3.png" alt="image" />
    </div>
    <div class="dot-shape4">
        <img src="assets/img/shape/dot4.png" alt="image" />
    </div>
    <div class="dot-shape5">
        <img src="assets/img/shape/dot5.png" alt="image" />
    </div>
    <div class="dot-shape6">
        <img src="assets/img/shape/dot6.png" alt="image" />
    </div>
</section>
