import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { AlertService } from "@full-fledged/alerts";
import { ApiService } from "src/app/Service/api.service";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    contactForm: FormGroup;
    countries: string[] = [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "East Timor",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Grenada",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea, North",
        "Korea, South",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "North Macedonia",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Qatar",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Sudan, South",
        "Suriname",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Togo",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Zambia",
        "Zimbabwe",
    ];

    name: FormControl = new FormControl("", [Validators.required]);
    businessEmail: FormControl = new FormControl("", [
        Validators.required,
        Validators.email,
    ]);
    phoneNumber: FormControl = new FormControl("", [Validators.required]);
    organization: FormControl = new FormControl("", [Validators.required]);

    lastName: FormControl = new FormControl("", [Validators.required]);
    jobTitle: FormControl = new FormControl("", [Validators.required]);
    description: FormControl = new FormControl("", [Validators.required]);
    ProjectTiming: FormControl = new FormControl("", [Validators.required]);
    country: FormControl = new FormControl("", [Validators.required]);

    responseMessage: string = "";
    constructor(
        private toast: AlertService,
        private fb: FormBuilder,
        private api: ApiService
    ) {}

    ngOnInit() {
        this.contactForm = this.fb.group({
            name: this.name,
            businessEmail: this.businessEmail,
            phoneNumber: this.phoneNumber,
            organization: this.organization,
            lastName: this.lastName,
            jobTitle: this.jobTitle,
            description: this.description,
            ProjectTiming: this.ProjectTiming,
            country: this.country,
        });
    }

    submit() {
        const payload = {
            first_name: this.contactForm.value.name.trim(),
            email: this.contactForm.value.businessEmail.trim(),
            phone_number: this.contactForm.value.phoneNumber.trim(),
            organization: this.contactForm.value.organization.trim(),
            last_name: this.contactForm.value.lastName.trim(),
            job_title: this.contactForm.value.jobTitle.trim(),
            // description: this.contactForm.value.description.trim(),
            // ProjectTiming: this.contactForm.value.ProjectTiming.trim(),
            country: this.contactForm.value.country,
        };

        if (!payload.first_name || !payload.email || !payload.phone_number) {
            return this.toast.danger(
                `${
                    (!payload.first_name && "name") ||
                    (!payload.email && "Business Email") ||
                    (!payload.phone_number && "Phone Number") ||
                    (!payload.email && "Business Email") ||
                    (!payload.last_name && "Last Name") ||
                    (!payload.job_title && "Job Title") ||
                    //  (!payload.description && "Description") ||
                    // (!payload.ProjectTiming && "Project Timing") ||
                    (!payload.country && "Country")
                } is required field`
            );
        }

        this.api.mailchimpContactUsAudience(payload).subscribe(
            (res: any) => {
                if (res) {
                    this.responseMessage =
                        "Thank you! We have received your message.";

                    this.resetForm(this.contactForm);
                    this.contactForm.reset();
                } else {
                    this.responseMessage =
                        "Something went worng! Please try again";
                    this.resetForm(this.contactForm);
                    this.contactForm.reset();
                }
            },
            (err) => this.api.catchBlock(err)
        );
    }
    resetForm(form: FormGroup) {
        form.reset();

        Object.keys(form.controls).forEach((key) => {
            form.get(key).setErrors(null);
        });
    }
}
