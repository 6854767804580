import { Component } from "@angular/core";

interface IFeatureItem{
  icon: string;
  front: {
    title: string;
    sub: string;
  };
  back:{
    title: string;
    sub: string;
  }
};

@Component({
    selector:'app-characteristics',
    templateUrl: './characteristics.component.html',
    styleUrls:['./characteristics.component.scss']
})
export class CharacteristicsComponent{
    homeFeaturesData:Array<IFeatureItem> = [
        {
          icon: "fa-solid fa-globe",
          front:{
            title: "World-class Security",
            sub: "SOC 2 Type II Compliant"
          },
          back:{
            title: "We take your cybersecurity seriously and fully comply to rigorious industry standards",
            sub: ""
          }
        },
        {
          icon: "fa-solid fa-industry",
          front:{
            title: "Real-time Visibility",
            sub: "Gain control of your shopfloor"
          },
          back:{
            title: "Tackle problems or capture opportunities as they happen, not hours or days later",
            sub: ""
          }
        },
        {
          icon: "fa-solid fa-circle-nodes",
          front:{
            title: "Single Platform",
            sub: "Merge data from IT-OT Systems for insight"
          },
          back:{
            title: "With a single platform integrate with all your IT and OT systems, you can eliminate manual data gathering and analysis",
            sub: ""
          }
        },
        {
          icon: "fa-solid fa-chart-line",
          front:{
            title: "Scalability",
            sub: "From single factory to global Operations"
          },
          back:{
            title: "We provide solutions that fit your needs and can grow with your business",
            sub: ""
          }
        },
        {
          icon: "fa fa-pencil-square",
          front:{
            title: "Customizable",
            sub: "No programming skills needed"
          },
          back:{
            title: "Easily customize your analytics, dashboards, reports, and AI - all in-house; or, have us do it for you",
            sub: ""
          }
        },
        {
          icon: "fa fa-plug",
          front:{
            title: "Plug-and-Play Functionality",
            sub: "Ready-to-use analytics, dashboards, and reports"
          },
          back:{
            title: "Our pre-built analytics, dashboards, and reports can quickly get your team up and running fast",
            sub: ""
          }
        },
        {
          icon: "fa fa-exchange",
          front:{
            title: "Digital Transformation Maturity",
            sub: "Whatever your starting point, let’s get you to the next level"
          },
          back:{
            title: "Whether you're still using spreadsheet and paper, or already having some level of automation, we can get you to the next level",
            sub: ""
          }
        },
        {
          icon: "fa fa-assistive-listening-systems",
          front:{
            title: "AI-Powered",
            sub: "AI Assistant for every function"
          },
          back:{
            title: "Let our AI assist you to do the hard stuff",
            sub: ""
          }
        },
      ];
}