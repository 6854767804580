<section class="d-ga-section">
  <div class="d-ga-main-container">
      <div class="d-ga-title-container">
          <h2>Step up to the next level with AI</h2>
          <div></div>
          <p>From performing day-to-day operations, to your most difficult manufacturing challenges, we give you the power of Artificial Intelligence to take productivity to the next level.</p>
      </div>
      <div class="d-ga-content-container">
        <div class="d-ga-content-img" style="background-image: url({{currentImage}});"></div>
        <div class="d-ga-content-accordion-container">
          <div *ngFor="let section of sections; let i = index;" class="d-ga-content-accordion-item">
            <div [ngClass]="{'active-accordion-border':currentIndex === i}"></div>
            <div (click) = "expand(i)" class="d-ga-content-accordion-header">
              <h2 [ngClass]="{'ative-color':currentIndex === i}">{{section.name}}</h2>
              <i *ngIf="currentIndex == i" class="fa fa-chevron-up"></i>
              <i *ngIf="currentIndex != i" class="fa fa-chevron-down"></i>
            </div>
            <div class="d-ga-content-accordion-content"
              [ngClass]="{ 'visible' : currentIndex === i }">
              <div class="" style="background-image: url({{currentImage}});"></div>
              <p>{{section.content}}</p>
              <!-- <a routerLink="/contact" type="button">
                Learn how 
                <i class="fas fa fa-chevron-right"></i>
            </a> -->
            </div>
          </div>
        </div>
      </div>
  </div>

</section>