<section class="d-sia-section ptb-110">
    <div class="d-sia-main-container">
        <h2>The Single Insight Advantage</h2>
        <div class="d-sia-cards-container">
            <div *ngFor="let card of cardData" class="flip-card">
                <div class="flip-card-inner">
                    <div class="flip-card-front">
                        <div class="d-sia-card">
                            <h4>{{card.title}}</h4>
                            <p>{{card.sub}}</p>
                        </div>
                    </div>
                    <div class="flip-card-back">
                        <p>{{card.back}}</p>
                    </div>

                </div>
                
            </div>
        </div>
        <div class="d-sia-explore-container">
            <div class="d-sia-explore-left">
                <h3>
                    Single Insight, your Digital Transformation Partner:
                </h3>
                <p>We know you have a lot of options when considering how (or if) to advance your Digital Maturity.  If you’re like most manufactures (even many of the big players), this can be challenging.</p>
                <p>At Single Insight, we’re not just software developers and AI specialists; we’re manufacturing experts who have walked in your (safety-toed) shoes.  We understand your needs.  And we can help.</p>
                <!-- <div class="d-sia-explore-mobile">
                    <img src="../../../assets/img/Final_First_blue.png" alt="">
                </div> -->
                <p>Here’s how:</p>
                <ul>
                    <li><span>We Assess your Operations and Needs: </span>you’re unique, so why settle for a one-size-fits-all solution?  We take the time to understand your needs and provide what’s right for you.</li>
                    <li><span>Implementation Scope and Pace: </span>with Single Insight, implementation can be enterprise-wide, one department, or even a single job-function at a time; you choose an implementation plan that works best for you.</li>
                    <li><span>Minimal Disruption: </span>because we don’t necessarily replace the data systems you currently have – but rather integrate the data they contain into a single platform – the disruption during implementation is minimal.</li>
                    <li><span>More than software: </span>we understand that even the best software will fall short without buy-in from your team;  we work with you and your team to help ensure your new software has the internal support and buy-in needed for success.</li>
                    <li><span>Outstanding Customer Support: </span>we’re with you every step of the way, and want to be your long-term Digital Transformation partner.</li>
                    <li><span>Lower Cost: </span>whether your facility is small, medium, or large, we offer competitive pricing that gives you an enterprise-wide capable platform at a price that, in many cases, is lower than single-function, non-customizable platforms.</li>
                </ul>
                <a routerLink="/contact" class="btn">Request Demo</a>
                <!-- <p>This will enable us to recommend solution tailored to your needs</p> -->
            </div>
            <div class="d-sia-explore-right">
                <img src="../../../assets/img/Final_First_blue.png" alt="">
            </div>
        </div>
    </div>

</section>