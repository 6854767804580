import { Component } from '@angular/core';

interface IApplication{
  title: string;
  content: string;
  image: string;
}

@Component({
  selector: 'app-ai-applications',
  templateUrl: './ai-applications.component.html',
  styleUrls: ['./ai-applications.component.scss']
})
export class AiApplicationsComponent {
    applications:Array<IApplication> = [
      {
        title: 'Inventory Optimization',
        content:` Single Insight Inventory Optimization leverages 
        cutting-edge AI, machine learning, and optimization 
        methods to help companies reduce their stockpile 
        of parts, raw materials, and completed products.`,
        image:'assets/img/inventory-optimization.jpg'
      },
      {
        title: ' Process Optimization',
        content:` Single Insight AI enhances production yield and 
        efficiency by applying advanced analytics to 
        operational data, identifying anomalies, offering 
        prioritized alerts, and suggesting optimization 
        actions, enabling engineers to maximize output and 
        energy efficiency. `,
        image:'assets/img/process-optimization.jpg'
      },
      {
        title: ' Production Scheduling',
        content:` Single Insight AI optimizes manufacturing schedules 
        to meet demand efficiently and cost-effectively, 
        offering scenario planning and impact visibility for 
        various manufacturing types, using an elastic solver 
        for adaptable scheduling. `,
        image:'assets/img/production-scheduling.jpg'
      },
      {
        title: 'Quality Assurance',
        content:` Single Insight AI enhances quality management 
        through anomaly detection, ensuring traceability, 
        and providing predictive insights, maintaining high 
        standards across manufacturing types, improving 
        product quality and operational efficiency. `,
        image:'assets/img/quality-assurance.jpg'
      },
      {
        title: ' Predictive Maintenance',
        content:` Deploy our PM Suite to analyze data and anticipate 
        when maintenance should be performed on 
        equipment. Single Insight PM uses machine learning 
        to learn from historical data and use live data to 
        analyze failure patterns `,
        image:'assets/img/predictive-maintenance.jpg'
      },
      {
        title: 'Supply Chain Management',
        content:` Single Insight's Sourcing Optimization Application 
        enhances sourcing decisions, reduces costs, and 
        mitigates supply chain risks by consolidating data 
        and leveraging AI for comprehensive monitoring 
        and risk management.
         `,
        image:'assets/img/supply-chain-management.jpg'
      },
      {
        title: ' Product Management',
        content:` Leveraging AI for data analysis, this application 
        categorizes products using 80/20 Quad and Quartile 
        analyses to simplify product lines, manage pricing, 
        allocate resources strategically, and maintain a 
        strategic business view for operational streamlining 
        and profit enhancement. `,
        image:'assets/img/product-management.jpg'

      },
      {
        title: ' Digital SQDIP',
        content:`The Digital SQDIP Application provides a simple, yet 
        powerful and secure visual management solution to 
        support Lean and Agile methodologies in today’s 
        data-driven operational environments.`,
        image:'assets/img/sqdip.jpg'
      },
      {
        title: 'Reporting',
        content:` Single Insight harnesses generative AI to analyze 
        manufacturing big data, offering live insights and 
        prescriptive recommendations, with a robust 
        algorithm that publishes reports directly to PDF, 
        Word, and PowerPoint via Microsoft 365 apps. `,
        image:'assets/img/quality-assurance.jpg'
      },
      {
        title: ' ESG',
        content:` Single Insight's ESG tool uses advanced AI, including 
        machine learning and NLP, to centralize ESG data, 
        automate reporting, refine emissions tracking, 
        monitor ESG goals, and generate insightful reports 
        with sentiment analysis. `,
        image:'assets/img/esg.jpg'
      },
      {
        title: 'Demand Forecasting',
        content:` Single Insight's AI Demand Forecasting improves 
        forecast accuracy using extensive data and AI for 
        detailed demand profiling, product and location
        specific forecasts, and scenario analysis, integrating 
        seamlessly with ERPs to enhance planner efficiency. `,
        image:'assets/img/demand-forecasting.jpg'
      },
      {
        title: 'Domain-specific AI Assistant',
        content:` Single Insight is developing specialized AI Assistants 
        for every role to enhance productivity, ensuring on
        time delivery, quality service, and cost savings with 
        domain-specific AI models.
         `,
        image:'assets/img/ai-domain-assistance.jpg'
      },
    ];

    totalItems: number = this.applications.length;
    itemsPerPage: number = 6;
    currentPage: number = 1;
}


